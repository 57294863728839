import React from 'react';
import PropTypes from 'prop-types';
// import { axios } from 'ApiClient';
import AgreementAlert from './Alert';

const Agreement = ({ agreements }) => (
  <div>
    {agreements.map(row => (
      <AgreementAlert row={row} key={row._id} />
    ))}
  </div>
);
Agreement.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default Agreement;
/* export default class AgreementComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    axios.get('/api/creator/agreements-to-sign').then((response) => {
      if (response.data.success && response.data.agreements) {
        this.setState({ data: response.data.agreements });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        {data.map(row => (
          <AgreementAlert row={row} key={row._id} />
        ))}
      </div>
    );
  }
} */
