import { PureComponent } from 'react';

export default class Alert extends PureComponent {
  constructor() {
    super();
    this.state = {
      visible: true,
    };
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  isVisible() {
    const { visible } = this.state;
    return visible;
  }
}
