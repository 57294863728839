import React from 'react';
import PropTypes from 'prop-types';
import Notifications from './notifications';
import CreatorDashboard from './CreatorDashboard';
import InfoRequiredAlert from '../../Payments/components/InfoRequiredAlert';
import Agreement from './agreement';
import WVValidator from '../../../../shared/helpers/WVValidator';
import SetupStripeAlert from './stripe/SetupStripeAlert';

const Dashboard = ({ influencer, addNotice, agreements }) => (
  <div>
    <Notifications />
    {
      influencer?.payment && !!influencer.payment.stripe.isDefault && !WVValidator.isEmpty(influencer.payment.stripe.accountId) && influencer.payment.enablePayments === true &&
      <InfoRequiredAlert creator={influencer} />
    }
    {
      influencer &&
      influencer.payment &&
      WVValidator.isEmpty(influencer.payment.stripe.accountId) &&
      WVValidator.isEmpty(influencer.payment.billCom.accountId) &&
      influencer.payment.enablePayments === true &&
      <SetupStripeAlert influencerOrAgent={influencer} type="creator"/>
    }
    <Agreement agreements={agreements} />
    <h2 className="dashboard-title"><img src="/images/creator/icon-dashboard.png" alt="some" />{' '}CREATOR DASHBOARD</h2>
    <CreatorDashboard addNotice={addNotice} influencer={influencer} />

  </div>
);

Dashboard.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  agreements: PropTypes.arrayOf(PropTypes.any),
  addNotice: PropTypes.func.isRequired,
  // loadingAgreements: PropTypes.bool.isRequired,
};
Dashboard.defaultProps = {
  agreements: [],
};
export default Dashboard;
