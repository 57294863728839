import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import withNotice from '../../App/store/with-notice';
import WVValidator from '../../../shared/helpers/WVValidator';
import SetupStripeAlert from '../Dashboard/components/stripe/SetupStripeAlert';
import StripeDashboard from './components/StripeDashboard';
import BillComDashboard from './components/BillComDashboard';
import InfoRequiredAlert from './components/InfoRequiredAlert';
import Breadcrumb from '../../../shared/components/BreadCrumbs';
// import PaymentsDisabledAlert from './components/PaymentsDisabledAlert';


class PaymentsDashboard extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    rtl: RTLProps.isRequired,
    addNotice: PropTypes.func.isRequired,
    // eslint-disable-next-line
    influencer: PropTypes.object.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    document.title = 'IL Creator Portal: Payments';
    const search = new URLSearchParams(window.location.search);
    if (search.get('action') === 'failed') {
      this.props.addNotice({
        message: 'Unable to configure your payment account.',
        type: 'error',
      });
    }
  }

  render() {
    const { influencer, history, addNotice } = this.props;
    // const { stripe, billCom } = influencer.payment;
    console.log(influencer);
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumb
              links={[
                { title: 'Home', path: '/' },
                { title: 'Payments', path: null, className: 'last-item-color' },
              ]}
              isBackButton
              backButtonUrl="/"
            />
          </Col>
        </Row>
        {
          influencer.payment &&
          WVValidator.isEmpty(influencer.payment.stripe.accountId) &&
          WVValidator.isEmpty(influencer.payment.billCom.accountId) &&
          <SetupStripeAlert influencerOrAgent={influencer} history={history} type="creator"/>
        }
        {
          influencer.payment && !!influencer.payment.stripe.isDefault && !WVValidator.isEmpty(influencer.payment.stripe.accountId) &&
          <div>
            <InfoRequiredAlert creator={influencer} />
            <Row>
              <StripeDashboard addNotice={addNotice} influencer={influencer} />
            </Row>
          </div>
        }
        {
          influencer.payment && !!influencer.payment.billCom.isDefault &&
          <BillComDashboard addNotice={addNotice} influencer={influencer} />
        }
      </Container>
    );
  }
}

export default withNotice(connect(state => ({
  rtl: state.rtl,
  influencer: state.session,
}))(PaymentsDashboard));
