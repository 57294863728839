import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import HeaderHero from './components/HeaderHero';
import Alert from '../../../shared/easydev2/shared/components/Alert';
import OnboardingDashboard from './components/OnboardingDashboard';
import Dashboard from './components/Dashboard';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import withNotice from '../../App/store/with-notice';
import { getAgreementsFetch, signAgreement, setStepCompleted } from '../../../redux/reducers/creator/Creator';
import TaxFormUploadModal, { TaxFormUploadModalId } from '../../../shared/components/modals/TaxFormUploadModal';
import Hook from '../../../shared/hooks';
import SetupStripeAlert from '../Dashboard/components/stripe/SetupStripeAlert';
import InfoRequiredAlert from '../Payments/components/InfoRequiredAlert';

const DefaultDashboard = ({
  influencer,
  agreements,
  loadingAgreements,
  deals,
  history,
  addNotice,
  allAgreements,
}) => {
  DefaultDashboard.propTypes = {
    // eslint-disable-next-line
    rtl: RTLProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    addNotice: PropTypes.func.isRequired,
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    agreements: PropTypes.arrayOf(PropTypes.any).isRequired,
    allAgreements: PropTypes.arrayOf(PropTypes.any).isRequired,
    deals: PropTypes.arrayOf(PropTypes.any),
    loadingAgreements: PropTypes.bool.isRequired,
  };
  DefaultDashboard.defaultProps = {
    deals: null,
  };

  const [taxFormData, setTaxFormData] = useState(null);
  const [statusFormData, setStatusFormData] = useState(null);
  const [loadingTaxForms, setLoadingTaxForms] = useState(true);
  const dispatch = useDispatch();

  const [showModalTaxFormUpload] = Hook.useShowModalHook(TaxFormUploadModalId);

  useEffect(() => {
    document.title = 'IL Creator Portal: Dashboard';
    dispatch(getAgreementsFetch());
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadingTaxForms(true);

    axios.get('/api/creator/get-tax-form').then((response) => {
      if (response.data.success) {
        // const currentYear = new Date().getFullYear();
        const { taxForm } = response.data;
        const taxFormObj = taxForm[0];
        const status = taxFormObj ? taxFormObj.approvalStatus?.value : '';

        setTaxFormData(taxFormObj || null);
        setStatusFormData(status);
        setLoadingTaxForms(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoadingTaxForms(false);
    });
  }
  if (!influencer) {
    return '';
  }
  const deal = deals && deals.length > 0 ? deals[0] : null;

  const missedNameParts = [];
  if (!influencer.first_name || (influencer.first_name.toLowerCase() === 'null')) {
    missedNameParts.push('First');
  }
  if (!influencer.last_name || (influencer.last_name.toLowerCase() === 'null')) {
    missedNameParts.push('Last');
  }
  const strNameParts = missedNameParts.join(' and ');
  const strNamePrefix = (missedNameParts.length === 1) ? '' : 'both';

  return (
    <Container className="dashboard creator-dashboard pb-4">
      <HeaderHero
        influencer={influencer}
      />
      {
        !deal &&
        influencer &&
        influencer.payment &&
        !influencer.payment.stripe.accountId &&
        !influencer.payment.billCom.accountId &&
        influencer.payment.enablePayments === true ?
        <SetupStripeAlert influencerOrAgent={influencer} type="creator"/> : null
      }
      {
        !deal && influencer?.payment && !!influencer.payment?.stripe?.isDefault && influencer.payment?.stripe?.accountId && influencer.payment.enablePayments === true ?
        <InfoRequiredAlert creator={influencer} /> : null
      }
      {(missedNameParts.length > 0) && (agreements.length > 0) && (
        <Alert isOpen color="info" bordered icon whiteBg >
          <div className="alert__content">
            <h4 className="alert-title mt-2 mb-3">Missing Required {strNameParts} Name</h4>
            <p>
              Both your first and last name are required to sign your Agreement.
              Please set {strNamePrefix} your {strNameParts.toLowerCase()} name in the Profile Settings by clicking <a href="/settings">here</a>.
            </p>
          </div>
        </Alert>
      )}
      {(!statusFormData
        && !loadingTaxForms
        && !influencer.validTaxForm?.value
        && influencer.payment.enablePayments
        && !influencer.payment?.stripe?.accountId
        && influencer.payment?.billCom.isDefault) && (
        <Alert isOpen color="danger" bordered icon whiteBg >
          <div className="alert__content">
            <h4 className="alert-title mt-2 mb-3">Missing Tax Form</h4>
            <Row>
              <Col md={10} className="mb-3">
                <p>
                  You are required to have a valid tax form on file. Use our secure upload form to send us your tax form.
                  Once uploaded, we will be notified and review the form. If there are any issues with upload, our&nbsp;
                  <b>Customer Success</b> team will promptly notify you.
                  If you have any questions, please contact <b>Customer Success</b> at&nbsp;
                  <a href="mailto:customersuccess@influencelogic.com">
                  customersuccess@influencelogic.com
                </a>.
                </p>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  style={{float: 'right'}}
                  onClick={() => {
                    showModalTaxFormUpload({source: 'creator'})
                  }}
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </div>
        </Alert>
      )}
      {(statusFormData === 'Rejected' || statusFormData === 'Expired') && (
        <Alert isOpen color="danger" bordered icon whiteBg>
          <div className="alert__content">
            <h4 className="alert-title mt-2 mb-3">Invalid Tax Form</h4>
            <Row>
              <Col md={10} className="mb-3">
                <p>
                  There was an issue with the tax form you recently uploaded. Please see the note below from our&nbsp;
                  <b>Customer Success</b> team and reupload after addressing the issue(s). If you have any questions, please
                  contact <b>Customer Success</b> at <a href="mailto:customersuccess@influencelogic.com">
                  customersuccess@influencelogic.com
                </a>.
                </p>
                <br/>
                <p>Message from <b>Customer Success:</b></p>
                <span dangerouslySetInnerHTML={{__html: taxFormData?.statusNote?.value}}/>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  style={{float: 'right'}}
                  onClick={() => {
                    showModalTaxFormUpload({source: 'creator'})
                  }}
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </div>
        </Alert>
      )}
      {deals && !deal ? (
        <Alert isOpen color="info" bordered icon whiteBg>
          <p className="py-2">
            It appears you have not yet signed up with us to take advantage of one of our many revenue generating
            affiliate offers.{' '}
            <a
              href={`mailto:${influencer && influencer.adminUser ? influencer.adminUser.email : 'sales@influencelogic.com'}`}
              className="font-weight-bold">Contact</a> us to learn more about how we can help. Thanks!
          </p>
        </Alert>) : null}
      {deal && influencer.onboarding.status === 'pending' ? (
        <OnboardingDashboard
          influencer={influencer}
          deal={deal}
          brands={influencer.brandResources}
          signAgreementDoc={(id) => {
            dispatch(signAgreement(id));
          }}
          loadingAgreements={loadingAgreements}
          agreements={agreements}
          allAgreements={allAgreements}
          reviewResources={() => {
            history.push(`/resources/${influencer.brandResources[0].brand.companyName}`);
          }}
          signBillCom={() => {
            dispatch(setStepCompleted(3));
          }}

        />
      ) : null}
      {deal && influencer.onboarding.status !== 'pending' ? (
        <Dashboard
          agreements={agreements}
          influencer={influencer}
          addNotice={addNotice}
        />) : null}
      <TaxFormUploadModal person="creator" record={influencer} updateData={() => { fetchData(); } }/>
    </Container>
  );
}

export default withNotice(connect(state => ({
  rtl: state.rtl,
  influencer: state.session,
  agreements: state.creatorCreatorReducer.agreementsToSign,
  allAgreements: state.creatorCreatorReducer.agreements,
  deals: state.session ? state.session.deals : [],
  loadingAgreements: state.creatorCreatorReducer.loading.agreements,
  brands: state.session ? state.session.brandResources : [],
}))(DefaultDashboard));
