import React from 'react';
import { Button } from 'reactstrap';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
// import Icon from 'mdi-react/InformationVariantIcon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertAbstract from '../../../../../shared/abstract/Alert';
import AlertC from '../../../../../shared/easydev2/shared/components/Alert';
import { openSignUrl } from '../../../../../shared/helpers/models/ServiceAgreement';

export default class Alert extends AlertAbstract {
  static propTypes = {
    row: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  sign = (e) => {
    const { row } = this.props;
    const button = e.target;
    const btnValue = button.innerHTML;
    button.disabled = true;
    button.innerHTML = 'Processing...';
    axios.post('/api/creator/sign-agreement', { id: row._id }).then((response) => {
      const { success, url, engine } = response.data;
      if (success && url) {
        openSignUrl(url, (loading) => { this.setState({ loading }); }, { engine });
      } else {
        console.log(response.data);
        button.disabled = false;
        button.innerHTML = btnValue;
      }
    }).catch((error) => {
      console.log(error);
      button.disabled = false;
      button.innerHTML = btnValue;
    });
  }

  render() {
    const { row } = this.props;
    const title = (row.type === 'keen') ? 'Service Provider Agreement' : 'Service Agreement';
    const bodyTitle = (row.type === 'keen') ? 'Keen Service Provider Agreement' : `Service Agreement for ${row.advertisers[0].companyName} - ${row.offers[0].name}`;
    return (
      <AlertC className="mt-2 mb-3" bordered color="info" isOpen={this.isVisible()} whiteBg>
        <button className="close" type="button" style={{ top: '16px' }} onClick={this.onDismiss}><i className="fa fa-times" /></button>
        <div className="alert__content">
          <h4 className="alert-title mt-2 mb-3"><FontAwesomeIcon icon={['fa', 'info-circle']} /> Action Required! {title} is ready to sign</h4>
          <p>
            Your new {bodyTitle} is now ready and available to be signed.
            Click the <b>Sign Agreement</b> button to enter our secure online signing process and complete.
            A copy of the document will be saved for you to view and download in the <b>Documents</b> section.
          </p>
          <p className="text-right">
            <Button onClick={this.sign} color="primary" className="mt-2 mb-2 radius-button" size="md">
              Sign Agreement
            </Button>
          </p>
        </div>
      </AlertC>
    );
  }
}
