/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import moment from 'moment';
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice, initializeSession } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const creatorPayoutReducer = createSlice({
  name: 'creator_creator',
  initialState: {
    loading: {
      total: false,
      agreements: false,
    },
    dateFrom: moment().startOf('month').set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).unix(),
    dateTo: moment().unix(),
    lastFetched: new Date(0),
    conversions: [],
    offers: [],
    selectedOffer: null,
    offerId: null,
    selectedButton: 'month_to_date',
    clicksCount: 0,
    conversionsCount: 0,
    payout: 0,
    haveUpfront: false,
    agreementsToSign: [],
    agreements: [],
    docusignSettings: {},

  },
  reducers: {
    setDates(state, action) {
      const { dateFrom, dateTo } = action.payload;
      state.dateFrom = dateFrom;
      state.dateTo = dateTo;
    },
    setSelectedButton(state, action) {
      const selectedButton = action.payload;
      state.selectedButton = selectedButton;
    },
    setLoading(state, action) {
      const isLoading = action.payload;
      state.loading.total = isLoading;
    },
    setLoadingByName(state, action) {
      const { isLoading, section } = action.payload;
      state.loading = { ...state.loading, [section]: isLoading };
    },
    setResponse(state, action) {
      const {
        clicksCount, conversionsCount, payout, offers, haveUpfront,
      } = action.payload;
      state.clicksCount = parseInt(clicksCount, 10);
      state.conversionsCount = parseInt(conversionsCount, 10);
      state.payout = parseFloat(payout);
      state.haveUpfront = !!haveUpfront;
      state.offers = offers;
      state.lastFetched = new Date();
      if (state.offerId) {
        const offer = state.offers.find(o => o._id === state.offerId);
        state.selectedOffer = offer;
      }
    },
    reSetResponse(state) {
      state.clicksCount = 0;
      state.conversionsCount = 0;
      state.payout = 0;
      state.haveUpfront = false;
      state.offers = [];
    },
    selectOffer(state, action) {
      const offerId = action.payload;
      const offer = state.offers.find(o => o._id === offerId);
      state.offerId = offerId;
      state.selectedOffer = offer;
      return state;
    },
    setAgreement(state, action) {
      const agreementsToSign = action.payload;
      state.agreementsToSign = agreementsToSign;
    },
    setAllAgreement(state, action) {
      const agreements = action.payload;
      state.agreements = agreements;
    },
    setDocusignSettings(state, action) {
      state.docusignSettings = action.payload;
    },

  },
});
export const {
  setDates,
  setSelectedButton,
  setLoading,
  setResponse,
  reSetResponse,
  selectOffer,
  setLoadingByName,
  setAgreement,
  setAllAgreement,
  setDocusignSettings,
} = creatorPayoutReducer.actions;

const getApiData = async ({ dateFrom, dateTo }) => {
  const url = `/api/creator/dashboard/get-stats?dateFrom=${moment.unix(dateFrom).format('YYYY-MM-DD')}&dateTo=${moment.unix(dateTo).format('YYYY-MM-DD')}`;
  const api = new ApiClient();
  return api.client.get(url);
};

const getAgreements = async () => {
  const api = new ApiClient();
  return api.client.get('/api/creator/agreements-to-sign');
};
const getAllAgreements = async () => {
  const api = new ApiClient();
  return api.client.get('/api/creator/agreements');
};

const signAgreementApi = async (id) => {
  const api = new ApiClient();
  return api.client.post('/api/creator/sign-agreement', { id });
};

const setStepCompletedApi = async (step) => {
  const api = new ApiClient();
  return api.client.post('/api/creator/dashboard/post-set-step-completed', { step });
};
const getCreatorApi = async (session) => {
  const api = new ApiClient();
  return api.client.post('/api/creator/get-creator', { authCode: session.authCode });
};

export const fetchData = ({ dateFrom, dateTo }) => async (dispatch) => {
  dispatch(setLoading(true));
  // eslint-disable-next-line max-len
  try {
    const response = await getApiData({ dateFrom, dateTo });
    if (response.data.success === true) {
      dispatch(setResponse(response.data.data));
    } else {
      dispatch(reSetResponse());
    }
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getAgreementsFetch = () => async (dispatch) => {
  dispatch(setLoadingByName({ section: 'agreements', isLoading: true }));
  // eslint-disable-next-line max-len
  try {
    const response = await getAgreements();
    const allAgreementsResp = await getAllAgreements();
    if (response.data.success === true) {
      dispatch(setAgreement(response.data.agreements));
    } else {
      dispatch(setAgreement([]));
    }
    if (allAgreementsResp.data.success === true) {
      dispatch(setAllAgreement(allAgreementsResp.data.agreements));
    } else {
      dispatch(setAllAgreement([]));
    }
    dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};
export const signAgreement = id => async (dispatch) => {
  dispatch(setLoadingByName({ section: 'agreements', isLoading: true }));
  // eslint-disable-next-line max-len
  try {
    const response = await signAgreementApi(id);
    if (response.data.success && response.data.url) {
      window.location.href = response.data.url;
    } else {
      console.log(response.data);
      throw new Error('Unable to Sign Document');
    }
    dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const checkCreator = (fnc) => async (dispatch, getState) => {
  try {
    const { session } = getState();
    const response = await getCreatorApi(session);
    if (!response.data.success === true || !response.data.user) {
      throw new Error('Invalid Creator');
    }
    const {
      user, deals, adminUser, contact, docusign, brandResources,
    } = response.data;
    dispatch(setDocusignSettings(docusign));
    user.deals = deals;
    user.adminUser = adminUser;
    user.contact = contact;
    user.brandResources = brandResources;
    if (!_.isEqual(user, session)) {
      dispatch(initializeSession(user));
    }
    if(fnc) fnc();
  } catch (e) {
    dispatch(initializeSession({}));
    document.location.href = '/login';
  }
};

export const setStepCompleted = step => async (dispatch) => {
  dispatch(setLoadingByName({ section: 'agreements', isLoading: true }));
  // eslint-disable-next-line max-len
  try {
    const response = await setStepCompletedApi(step);
    if (!response.data.success) {
      console.log(response.data);
      throw new Error('Unable to Sign Document');
    }
    // dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
    dispatch(checkCreator());
  } catch (error) {
    console.log(error);
    // dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export default creatorPayoutReducer.reducer;
