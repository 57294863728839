import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ViewHelpers from 'admin-bro/lib/backend/utils/view-helpers';
import SidebarLink from './SidebarLink';
import SidebarCategory from '../../../admin/Layout/sidebar/SidebarCategory';
// import SidebarCategory from './SidebarCategory';
// import WVUtilities from '../../../../shared/helpers/WVUtilities';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    // eslint-disable-next-line
    currentAdmin: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    session: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    docusignSettings: PropTypes.object.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    // const h = new ViewHelpers();
    // const { currentAdmin } = this.props;
    const { session, docusignSettings } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Home" icon="home" route="/" />
          <SidebarLink title="My Content" icon="camera-video" route="/my-content" />
          <SidebarLink title="Payments" icon="gift" route="/payments" />
          {docusignSettings?.enabled && (
            <SidebarLink title="Documents" icon="file-empty" route="/documents" />
          )}
          {session
            && typeof session.brandResources !== 'undefined'
            && session.brandResources.length > 0
            && session.onboarding
            && session.onboarding.step1Status === 'complete' && (
            <SidebarCategory title="Resources" icon="picture">
              {session.brandResources.map(resource => (<SidebarLink
                key={`${resource.brand?.companyName}-${resource._id}`}
                title={resource.brand?.companyName}
                route={`/resources/${resource.brand?.companyName}`}
              />))}
            </SidebarCategory>
          )}
        </ul>
      </div>
    );
  }
}

export default connect(state => ({
  session: state.session,
  docusignSettings: state.creatorCreatorReducer.docusignSettings,
}))(SidebarContent);
/*
 * <SidebarCategory title="Layout" icon="layers">
          <button className="sidebar__link" type="button" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button className="sidebar__link" type="button" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory>
 */
