import React from 'react';
import PropTypes from 'prop-types';

const HeaderHero = ({ influencer }) => (
  <div className="header-hero text-center">
    <h1>Welcome{(influencer.first_name && (influencer.first_name.toLowerCase() !== 'null')) ? `, ${influencer.first_name}` : ''}</h1>
    {/* <span className="hero-text">“You {'can\'t'} connect the dots looking forward; you can only connect them looking backwards” - <br />
      So you have to trust that the dots will somehow connect<br />
      in your future.
    </span> */}
  </div>
);

HeaderHero.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default HeaderHero;
