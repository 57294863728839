import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../../../creator/Dashboard';
import DealDetails from '../../../creator/DealDetails';
import MyContent from '../../../creator/MyContent';
import Payments from '../../../creator/Payments';
import PaymentHistory from '../../../creator/PaymentHistory/PaymentHistory';
import Layout from '../../../creator/Layout/index';
import Settings from '../../../creator/Settings';
import Documents from '../../../creator/Documents';
import InternationalAccount from '../../../creator/InternationalAccount';
import BrandResources from '../../../creator/BrandResources';
import BrandOpportunities from '../../../creator/BrandOpportunities';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/dashboard/:offerId" exact component={DealDetails} />
        <Route path="/my-content" exact component={MyContent} />
        <Route path="/payments" exact component={Payments} />
        <Route path="/payment-history" exact component={PaymentHistory} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/documents" exact component={Documents} />
        <Route path="/create-account" exact component={InternationalAccount} />
        <Route path="/resources/:brandId" exact component={BrandResources} />
        <Route path="/opportunities" exact component={BrandOpportunities} />
      </Switch>
    </div>
  </div>
);
