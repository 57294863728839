/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { PureComponent } from 'react';
import moment from 'moment';
import { Row, Col, Container, Popover, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ReactQr from 'react-awesome-qr';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Panel from '../../../shared/components/Panel';
import SummaryPanel from './summary/SummaryPanel';
import TopButtonsPeriod from '../Dashboard/components/TopButtonsPeriod';
import Breadcrumb from '../../../shared/components/BreadCrumbs';
import { selectOffer, fetchData } from '../../../redux/reducers/creator/Creator';
import Conversions from './summary/Conversions';
import CopyToClipboard from '../../../shared/components/CopyToClipboard';


class CreatorDashboard extends PureComponent {
  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    session: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedOffer: PropTypes.objectOf(PropTypes.any),
    dispatch: PropTypes.func.isRequired,
    dateFrom: PropTypes.number.isRequired,
    dateTo: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    selectedButton: PropTypes.string.isRequired,
    offers: PropTypes.arrayOf(PropTypes.any).isRequired,
    lastFetched: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    selectedOffer: null,
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.downloadQr = this.downloadQr.bind(this);
    this.setQrCodeBase64 = this.setQrCodeBase64.bind(this);
    this.toggleQrPopover = this.toggleQrPopover.bind(this);
    this.getVanityUrlRedirect = this.getVanityUrlRedirect.bind(this);
    this.state = {
      loadingRedirect: false,
      redirectUrl: false,
      qrPopoverOpen: false,
      qrCodeBase64: null,
      showQrCodeTargetId: `showQrCode_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`,
    };
  }

  componentDidMount() {
    const {
      dateFrom, dateTo, dispatch, match, lastFetched,
    } = this.props;
    if (!lastFetched || moment(lastFetched).diff(moment(), 'seconds') < -30) {
      dispatch(fetchData({ dateFrom, dateTo }));
    }
    if (match.params.offerId) {
      dispatch(selectOffer(match.params.offerId));
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const {
      dateFrom, dateTo, dispatch, match, selectedButton, selectedOffer,
    } = this.props;
    if ((prevProps.match.params.offerId !== match.params.offerId)) {
      dispatch(selectOffer(match.params.offerId));
    }
    if ((prevProps.selectedOffer !== selectedOffer)) {
      this.getVanityUrlRedirect();
    }
    if ((prevProps.selectedButton !== selectedButton && selectedButton !== 'custom') || (selectedButton === 'custom' && (prevProps.dateFrom !== dateFrom || prevProps.dateTo !== dateTo))) {
      dispatch(fetchData({ dateFrom, dateTo }));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setQrCodeBase64(url, id) {
    console.log(id);
    this.setState({
      qrCodeBase64: url,
    });
  }

  async getVanityUrlRedirect() {
    const {
      session, match,
    } = this.props;
    this.setState(prevState => ({ ...prevState, loadingRedirect: true, redirectUrl: null }));
    try {
      const deal = session && session.deals && session.deals.find(d => d.offer && d.offer._id === match.params.offerId && d.dealTerms.cpa_status === 'Active');
      if (deal && deal.dealTerms && deal.dealTerms.couponCode) {
        const resp = await axios.post('/api/creator/find-vanity-url-redirect', { couponCode: deal.params.dealTerms.coupon_code, offer: deal.params.ilDealData.offer._id });
        if (resp.data.success && resp.data.resultUrl) {
          this.setState(prevState => ({ ...prevState, loadingRedirect: false, redirectUrl: resp.data.resultUrl.concat('&aff_unique1=qr') }));
        } else {
          throw new Error(resp.data.error);
        }
      }
    } catch (e) {
      console.error(e);
      this.setState(prevState => ({ ...prevState, loadingRedirect: false, redirectUrl: null }));
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.setState({ qrPopoverOpen: false });
    }
  }

  toggleQrPopover() {
    const { qrPopoverOpen } = this.state;
    this.setState(prevState => ({ ...prevState, qrPopoverOpen: !qrPopoverOpen }));
  }

  downloadQr() {
    const { selectedOffer, session, match } = this.props;
    const { qrCodeBase64 } = this.state;
    const deal = session && session.deals && session.deals.find(d => d.offer && d.offer._id === match.params.offerId && d.dealTerms.cpa_status === 'Active');
    const name = selectedOffer?.offer_name ? `QR Code ${selectedOffer.offer_name}.png` : 'QR Code.png';
    if (deal.dealTerms?.referral_url?.trim() && qrCodeBase64) {
      const link = document.createElement('a');
      document.body.appendChild(link); // for Firefox
      link.setAttribute('href', qrCodeBase64);
      link.setAttribute('download', name);
      link.click();
    }
  }

  render() {
    const {
      isFetching, selectedOffer, offers, session, match,
    } = this.props;
    const {
      qrPopoverOpen, showQrCodeTargetId, loadingRedirect, redirectUrl,
    } = this.state;
    const deal = session && session.deals && session.deals.find(d => d.offer && d.offer._id === match.params.offerId && d.dealTerms.cpa_status === 'Active');
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumb
              links={[
                { title: 'Home', path: '/' },
                { title: `${selectedOffer ? selectedOffer.offer_name : ''} Dashboard`, path: null, className: 'last-item-color' },
              ]}
              isBackButton
              backButtonUrl="/"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              {selectedOffer && selectedOffer.brandLogo && (
                <img className="offer-name-logo" alt={selectedOffer.offer_name} src={selectedOffer.brandLogo} />
              )}
            </div>
            <h3>{selectedOffer ? selectedOffer.offer_name : ''} Dashboard</h3>
          </Col>
        </Row>
        <Row className="offer-summary-panel">
          <Panel showButtons={false}>
            <TopButtonsPeriod />
            {deal && deal.dealTerms.referral_url ?
              (
                <>
                  <CopyToClipboard value={deal.dealTerms.referral_url || ''} className="mt-1 mb-2">
                    <span className="il-text-gray">
                      <span className="fa fa-link" />{' '}<b>Referral Url:</b>{' '}
                      <span className="il-text-gray">
                        {deal.dealTerms.referral_url}
                      </span>
                      <span>{'  '}</span>
                    </span>
                  </CopyToClipboard>
                  {!loadingRedirect && redirectUrl && (
                    <span>
                      <Popover
                        id="popoverQrCode"
                        placement="right"
                        isOpen={qrPopoverOpen}
                        target={showQrCodeTargetId}
                        trigger="click"
                        toggle={this.toggleQrPopover}
                      >
                        <PopoverBody
                          style={{ width: '218px', height: '240px' }}
                        >
                          <div
                            ref={this.wrapperRef}
                          >
                            <a
                              className="il-color-deep-blue bold-text"
                              role="button"
                              onClick={() => this.downloadQr()}
                              style={{ fontSize: '150%' }}
                            >
                              Download QR Code
                            </a>
                            <ReactQr
                              text={`${redirectUrl.trim()}${redirectUrl.trim().indexOf('?') !== -1 ? '&' : '?'}aff_unique1=qr`}
                              size={1000}
                              callback={this.setQrCodeBase64}
                            />
                          </div>
                        </PopoverBody>
                      </Popover>
                      <Tooltip title="Show QR Code">
                        <IconButton
                          id={showQrCodeTargetId}
                          size="small"
                          className="material-table__toolbar-button"
                          onClick={this.toggleQrPopover}
                          tyle={{ paddingLeft: '8px' }}
                        >
                          <span className="fa fa-qrcode" style={{ fontSize: '13px' }} />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )}
                </>
                ) : null
            }
            {deal && deal.dealTerms.coupon_code ?
              (
                <CopyToClipboard value={deal.dealTerms.coupon_code || ''} className="d-block mt-1 mb-2">
                  <span className="il-text-gray">
                    <span className="fa fa-tag" />{' '}<b>Promo Code:</b> {' '}
                    <span className="il-text-gray">
                      {deal.dealTerms.coupon_code}
                    </span>
                  </span>
                </CopyToClipboard>
              ) : null
            }
            <SummaryPanel
              dateFrom={this.props.dateFrom}
              dateTo={this.props.dateTo}
              isFetching={isFetching}
              selectedOffer={selectedOffer}
              offers={offers}
            />
          </Panel>
        </Row>
        <Row className="offer-details-dashboard">
          {selectedOffer && selectedOffer.conversionsArray && selectedOffer.conversionsArray.length > 0 ? (
            <Panel showButtons={false}>
              <Conversions
                isFetching={isFetching}
                conversions={selectedOffer.conversionsArray.map(c => ({
                  date: c.date,
                  dateOriginal: c.dateOriginal,
                  payout: c.payout,
                }))}
                offer={selectedOffer}
              />
            </Panel>
            ) : null}
        </Row>
      </Container>
    );
  }
}

export default connect(state => ({
  session: state.session,
  dateFrom: state.creatorCreatorReducer.dateFrom,
  dateTo: state.creatorCreatorReducer.dateTo,
  selectedOffer: state.creatorCreatorReducer.selectedOffer,
  selectedButton: state.creatorCreatorReducer.selectedButton,
  lastFetched: state.creatorCreatorReducer.lastFetched,
  isFetching: state.creatorCreatorReducer.loading.total,
  offers: state.creatorCreatorReducer.offers,
}))(CreatorDashboard);
