import React from 'react';
import PropTypes from 'prop-types';

const DetailsCellValue = ({ record }) => (
  <span>
    {record.thumbnail && (
      <img style={{ float: 'left', maxWidth: 100, marginRight: 10 }} src={record.thumbnail} alt="" />
    )}
    {!record.thumbnail && (
      <img style={{ float: 'left', maxWidth: 100, marginRight: 10 }} src="/images/brands/thumbnail-default.png" alt="" />
    )}
    {record.mediaUrl ? (
      <a target="_blank" rel="noreferrer" href={record.mediaUrl}>{record.title}</a>
    ) : (record.title || '')
    }
  </span>
);
DetailsCellValue.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default DetailsCellValue;
