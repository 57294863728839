import React, { PureComponent } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import SettingsIcon from 'mdi-react/SettingsIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WVFormatter from '../../../../shared/helpers/WVFormatter';
import WVValidator from '../../../../shared/helpers/WVValidator';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import Panel from '../../../../shared/components/Panel';
// import Alert from '../../../../shared/components/Alert';
import PayoutModal from './PayoutModal';
import TransfersTable from './TransfersTable';

function GraySpinner() {
  return (
    <span>
      <div
        className="spinner-border il-text-gray spinner-border-sm"
        style={{ width: '1.5rem', height: '1.5rem', borderWidth: '.10em' }}
      />
    </span>
  );
}

class StripeDashboard extends PureComponent {
  static propTypes = {
    addNotice: PropTypes.func.isRequired,
    // eslint-disable-next-line
    influencer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentBalance: 0,
      availableBalance: 0,
      currency: 'usd',
      loading: {
        balance: false,
        payout: false,
      },
    };
  }

  componentDidMount() {
    this.fetchStripeBalance();
  }

  // HANDLERS
  onPayoutButton = () => {
    this.setState({
      loading: {
        payout: true,
      },
    });

    axios({
      method: 'post',
      url: '/api/creator/stripe/create-payout',
    }).then((response) => {
      if (response.data.success) {
        this.props.addNotice({
          message: 'Successfully generated a payout of the available balance.',
          type: 'success',
        });
        this.fetchStripeBalance();
      } else {
        console.log('CATCH ERROR: ', response?.data?.error);
        this.props.addNotice({
          message: response?.data?.error ?? 'Something went wrong!',
          type: 'error',
        });
      }

      this.setState({
        loading: {
          payout: false,
        },
      });
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      this.props.addNotice({
        message: error,
        type: 'error',
      });
    });
  };

  onAddBalanceButton = () => {
    this.setState({
      loading: {
        addBalance: true,
      },
    });

    axios({
      method: 'post',
      url: '/api/creator/stripe/test-charge-balance',
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success) {
        this.setState({
          loading: {
            addBalance: false,
          },
        });

        this.props.addNotice({
          message: 'Successfully charged your balance with test $100',
          type: 'success',
        });
        this.fetchStripeBalance();
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
    });
  }
  //

  // METHODS
  fetchStripeBalance() {
    this.setState({
      loading: {
        balance: true,
      },
    });

    axios.get('/api/creator/stripe/balance').then((response) => {
      const { balance } = response.data;
      this.setState({
        currentBalance: balance ? balance.available[0].amount + balance.pending[0].amount : 0,
        availableBalance: balance ? balance.available[0].amount : 0,
        currency: balance?.available[0]?.currency || 'usd',
        loading: {
          balance: false,
        },
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: {
          balance: false,
        },
      });
    });
  }
  //

  render() {
    const { influencer } = this.props;
    const { currentBalance, availableBalance, currency, loading } = this.state;

    return (
      influencer.payment && !WVValidator.isEmpty(influencer.payment.stripe.accountId) ?
        <>
          <Col md={12} className="mt-3 ml-auto payment-title">
            <div>
              <img className="payment-title-image" alt="Payments" src="/images/creator/paymentSymbol.png" />
            </div>
            <h3 style={{ float: 'left' }}>Payments</h3>
            <UncontrolledDropdown style={{ textAlign: 'end' }}>
              <DropdownToggle className="icon creator-settings-icon" outline>
                <p><SettingsIcon className="mr-0" style={{ width: '26px', height: '24px' }} /></p>
              </DropdownToggle>
              <DropdownMenu right className="dropdown__menu">
                <DropdownItem>
                  <Link to="/settings">
                    <p>
                      <span className="topbar__link-icon lnr lnr-cog" />
                      Payment Settings
                    </p>
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Panel showButtons={false} md={12} panelClass="panel-no-vertical-padding">
            <Row>
              <Col xs="auto" className="my-1">
                <div>
                  <img className="il-payments-logo" alt="Creator avatar" src={influencer && influencer.logo ? influencer.logo : '/images/user/avatarLogo1.png'} />
                </div>
              </Col>
              <Col xs="auto" md={3} className="pl-1 my-2">
                <h3 className="il-color-deep-blue font-weight-bold">{`${influencer && influencer.first_name} ${influencer && influencer.last_name}`}</h3>
                {
                  (influencer && influencer.accountType !== undefined && influencer.accountType === 1) &&
                  <h4>{`${influencer.companyName}`}</h4>
                }
                <span className="text-success">
                  <FontAwesomeIcon icon={['fab', 'cc-stripe']} size="2x" />
                </span>
                <a
                  className="il-payments-black-link"
                  href="/stripe/dashboard?account=true"
                  target="_blank"
                >
                  View Stripe account
                </a>
              </Col>
              <Col xs="12" md={4}>
                <Card className="il-payment-card-balance">
                  <CardBody className="il-payments-balance-body">
                    <Row>
                      <Col className="mr-0 my-auto il-payments-balance-title">
                        <h4>Your Balance:</h4>
                      </Col>
                      <Col style={{ minWidth: '100px' }}>
                        <Row className="align-items-center">
                          <Col xs="auto" style={{ textAlign: 'center' }}>
                            <h3 className="il-payments-balance">
                              {WVFormatter.formatCurrency(currentBalance / 100, currency)}
                            </h3>
                            <div className="il-payments-available-balance">
                              {WVFormatter.formatCurrency(availableBalance / 100, currency)} available
                            </div>
                          </Col>
                          <Col xs="auto">
                            {loading.balance && GraySpinner()}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="auto my-3" className="ml-auto text-center">
                <ButtonWithSpinner
                  className="px-5 il-payment-pay-btn mb-1 mr-0 btn-sm"
                  type="button"
                  color="primary"
                  onClick={this.onPayoutButton}
                  loading={loading.payout}
                  disabled={availableBalance < 1}
                >
                  <span style={{ fontSize: '18px' }}><b>$</b> Payout Now</span>
                </ButtonWithSpinner>
                <a
                  className="il-payments-black-link d-block"
                  href="/stripe/dashboard"
                  target="_blank"
                >
                  View payouts on Stripe
                </a>
              </Col>
            </Row>
          </Panel>
          <PayoutModal />
          <Panel showButtons={false} md={12} panelClass="panel-no-vertical-padding">
            <TransfersTable isStripe />
          </Panel>
        </>
        :
        <div />
    );
  }
}

export default StripeDashboard;
