import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export default class ButtonWithSpinner extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    color: 'primary',
    disabled: false,
    outline: false,
    className: '',
    children: '',
    size: 'md',
  };

  render() {
    const {
      loading,
      color,
      disabled,
      outline,
      onClick,
      className,
      children,
      size,
    } = this.props;

    return (
      <Button
        className={className}
        type="button"
        outline={outline}
        color={color}
        onClick={onClick}
        disabled={disabled}
        size={size}
      >
        {
          loading &&
          <span className="pr-2">
            <div className={`spinner-border spinner-border-sm ${color}`} />
          </span>
        }
        <span>{children}</span>
      </Button>
    );
  }
}
