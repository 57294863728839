import React, { PureComponent } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import withNotice from '../../App/store/with-notice';
import BrandOpportunitiesPage from './components/BrandOpportunitiesPage';
import Breadcrumbs from '../../../shared/components/BreadCrumbs';


class BrandOpportunities extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    rtl: RTLProps.isRequired,
    addNotice: PropTypes.func.isRequired,
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    document.title = 'IL Creator Portal: Opportunities';
  }

  render() {
    const { addNotice, influencer } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Opportunities', path: false },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <BrandOpportunitiesPage addNotice={addNotice} influencer={influencer} />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withNotice(connect(state => ({
  rtl: state.rtl,
  influencer: state.session,
}))(BrandOpportunities));
