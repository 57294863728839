import React, { PureComponent } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import withNotice from '../../App/store/with-notice';
import Panel from '../../../shared/components/Panel';
import { getOffersWithPayables } from '../../../redux/reducers/creator/Payout';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import WVFormatter from '../../../shared/helpers/WVFormatter';
import { momentUTCToLocalDate } from '../../../shared/helpers/Dates';
import Alert from '../../../shared/easydev2/shared/components/Alert';

const columnTransform = {
  name: {
    title: 'Name',
    valueFunc: record => (<div>{record.name} {record.status !== 'complete' ? (<span className="text-danger">*</span>) : null}</div>),
    sort: record => (record.name || ''),
  },
  date: {
    title: 'Date',
    valueFunc: (record) => {
      console.log(moment(record.startDate));
      // console.log(record, `${momentUTCToLocalDate(moment(record.startDate)).format('YYYY-MM-DD')} - ${momentUTCToLocalDate(moment(record.endDate)).format('YYYY-MM-DD')}`);
      return (`${momentUTCToLocalDate(moment(record.startDate)).format('YYYY-MM-DD')} - ${momentUTCToLocalDate(moment(record.endDate)).format('YYYY-MM-DD')}`);
    },
    sort: record => record.endDate,
  },
  type: {
    title: 'Type',
    valueFunc: (record) => {
      if (record.type === 'upfront_payout') {
        return (<span className="badge badge-primary">Upfront Payout</span>);
      }
      if (record.type === 'default') {
        return (<span className="badge badge-secondary">Default</span>);
      }
      if (record.type === 'upfront_credit') {
        return (<span className="badge badge-danger">Upfront Credit</span>);
      }
      return '';
    },
    sort: record => record.type,
  },
  payouts: {
    title: 'Payout',
    valueFunc: record => (WVFormatter.formatCurrency(record.ilPayoutAmount || 0)),
    sort: record => Number(record.ilPayoutAmount || 0),
  },
};

class PayableHistory extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    rtl: RTLProps.isRequired,
    // eslint-disable-next-line
    influencer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    offers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    loading: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOffersWithPayables());
    document.title = 'IL Creator Portal: Dashboard';
  }

  render() {
    const { offers, loading } = this.props;
    // console.log(offers, 'offers');
    return (
      <Container className="dashboard">
        {offers && offers[0] && offers[0].payables ? offers.map((offer) => {
          const payables = offer.payables.map(({
            status,
            type,
            _id,
            ilPayoutAmount,
            accountsPayablesList,
          }) => ({
              _id,
              status,
              type,
              ilPayoutAmount,
              name: accountsPayablesList[0].name,
              startDate: accountsPayablesList[0].startDate,
              endDate: accountsPayablesList[0].endDate,
          }));
          if (offer.additionalOffer) {
            payables.push({
              status: 'pending',
              type: '-',
              ilPayoutAmount: offer.additionalOffer.payout,
              name: 'Recent Payable',
              endDate: (new Date()).toString(),
              startDate: payables ? moment(payables[0].endDate).add(1, 'day').toDate() : (new Date()).toString(),
            });
          }
          // console.log(offer);
          return (
            <Row key={offer._id}>
              <Col>
                <Panel title={`${offer.name} Payment History`} >
                  <MatTableForRedux
                    dataArray={payables}
                    selectable={false}
                    columns={columnTransform}
                    version={(new Date()).toString()}
                    rowsPerPage={10}
                    defaultOrder={{
                      order: 'desc',
                      orderBy: 'date',
                    }}
                  />
                  <Row>
                    <Col><h5 className="text-danger mt-3">* amount is not yet finalized and will be reviewed by IL</h5></Col>
                  </Row>
                </Panel>
              </Col>
            </Row>
          );
        }) : (
          <div>
            {loading.offers ?
              (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>) :
              (
                <Alert color="warning" isOpen bordered icon whiteBg >
                  <div className="alert__content">
                    <p><span className="bold-text">Warning!</span> Unable To find Deals for this Influencer</p>
                  </div>
                </Alert>
              )
            }
          </div>
        )}
      </Container>
    );
  }
}
/* export default withNotice(connect(state => {
    console.log(state.creatorPayoutReducer);
})(PayableHistory)); */

export default withNotice(connect(state => ({
  rtl: state.rtl,
  influencer: state.session,
  offers: state.creatorPayoutReducer.offers,
  loading: state.creatorPayoutReducer.loading,
}))(PayableHistory));
