/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import AlertC from '../../../../../shared/easydev2/shared/components/Alert';

export default class Alert extends PureComponent {
  static propTypes = {
    notification: PropTypes.objectOf(PropTypes.any).isRequired,
    closeAlert: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      visible: true,
      color: 'info',
    };
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    const { closeAlert, notification } = this.props;
    this.setState({ visible: false });
    closeAlert(notification);
  };

  imgStyle = () => {
    const { notification } = this.props;
    const style = {
      height: '150px',
      width: '150px',
      minHeight: '150px',
      minWidth: '150px',
      background: `url(${notification.imageUrl}) center center no-repeat`,
      backgroundSize: 'contain',
      marginLeft: '10px',
      marginTop: '10px',
      marginBottom: '10px',
      padding: '0px',
    };
    return style;
  };

  body = () => {
    const { notification } = this.props;
    const { visible, color } = this.state;
    return (
      <AlertC color={color} bordered isOpen={visible}>
        {notification.imageUrl && <div className="" style={this.imgStyle()} />}
        <button className="close" type="button" onClick={this.onDismiss} style={{ top: '24px' }}><i className="fa fa-times" /></button>
        <div className="alert__content w-100" style={{ minHeight: '64px', paddingTop: '10px', paddingBottom: (notification.actionButtonText && notification.actionButtonUrl) ? '70px' : '1rem' }}>
          {notification.headlineText && (
            <h4 className="il-color-deep-blue mt-0 font-weight-bold"><img src="/images/creator/new-icon.png" className="text-icon" alt="icon new" /> {notification.headlineText}</h4>
          )}
          <div className="notification__text mt-2" dangerouslySetInnerHTML={{ __html: notification.notificationText }} />
          {notification.actionButtonText && notification.actionButtonUrl && (
            <a href={notification.actionButtonUrl}>
              <Button className="mt-4 radius-button" size="md" color="primary" style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                {notification.actionButtonText}
              </Button>
            </a>
          )}
        </div>
      </AlertC>
    );
  };

  bodyHeadline = () => {
    const { notification } = this.props;
    const { visible, color } = this.state;
    return (
      <AlertC color={color} bordered className="alert--bordered mb-4 alert--notification" isOpen={visible}>
        <button className="close" type="button" onClick={this.onDismiss} style={{ top: '24px' }}><i className="fa fa-times" /></button>
        <div
          className="alert__content w-100"
          style={{
            paddingTop: '55px',
            paddingBottom: '55px',
          }}
        >
          <h4 style={{ textAlign: 'center' }} className="il-color-deep-blue mt-0 font-weight-bold">{notification.headlineText}</h4>
          {notification.actionButtonText && notification.actionButtonUrl && (
            <a href={notification.actionButtonUrl}>
              <Button className="mt-4 radius-button" size="md" color="primary" style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                {notification.actionButtonText}
              </Button>
            </a>
          )}
        </div>
      </AlertC>
    );
  };

  bodyNotification = () => {
    const { notification } = this.props;
    const { visible, color } = this.state;
    return (
      <Alert color={color} className="mb-4" isOpen={visible} bordered whiteBg icon>
        <button className="close" type="button" onClick={this.onDismiss} style={{ top: '24px' }}><i className="fa fa-times" /></button>
        <div
          className="alert__content w-100 pt-3"
          style={{
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '18px',
            paddingBottom: (notification.actionButtonText && notification.actionButtonUrl) ? '70px' : '1rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className="notification__text"
            dangerouslySetInnerHTML={{ __html: notification.notificationText }}
          />
          {notification.actionButtonText && notification.actionButtonUrl && (
            <a href={notification.actionButtonUrl}>
              <Button className="mt-4 radius-button" size="md" color="primary" style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                {notification.actionButtonText}
              </Button>
            </a>
          )}
        </div>
      </Alert>
    );
  };

  bodyImageNotification = () => {
    const { notification } = this.props;
    const { visible, color } = this.state;
    return (
      <Alert color={color} className="mb-4" isOpen={visible}>
        {notification.imageUrl && <div className="alert__icon" style={this.imgStyle()} />}
        <button className="close" type="button" onClick={this.onDismiss} style={{ top: '24px' }}><i className="fa fa-times" /></button>
        <div
          className="alert__content w-100"
          style={{
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '18px',
            paddingBottom: (notification.actionButtonText && notification.actionButtonUrl) ? '70px' : '1rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className="notification__text"
            dangerouslySetInnerHTML={{ __html: notification.notificationText }}
          />
          {notification.actionButtonText && notification.actionButtonUrl && (
            <a href={notification.actionButtonUrl}>
              <Button className="mt-4 radius-button" size="md" color="primary" style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                {notification.actionButtonText}
              </Button>
            </a>
          )}
        </div>
      </Alert>
    );
  };

  render() {
    const { notification } = this.props;
    if (!notification.imageUrl) {
      if (notification.headlineText && !notification.notificationText) {
        return this.bodyHeadline();
      } else if (!notification.headlineText && notification.notificationText) {
        return this.bodyNotification();
      }
    } else if (!notification.headlineText && notification.notificationText) {
      return this.bodyImageNotification();
    }
    return this.body();
  }
}
