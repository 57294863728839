import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { Row, Spinner, Badge } from 'reactstrap';
import { axios } from 'ApiClient';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
// import Tooltip from '@material-ui/core/Tooltip';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import WVFormatter from '../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import Alert from '../../../../shared/easydev2/shared/components/Alert';

const columnTransform = {
  date: {
    title: 'Date',
    valueFunc: (record) => {
      if (record.paymentDate) {
        return WVFormatter.formatDateForCreatorsPaymentsTables(record.paymentDate);
      }
      if (!record.paymentDate && record.payableJournals && record.payableJournals.length > 0) {
        const payableJournal = record.payableJournals[record.payableJournals.length - 1];
        return WVFormatter.formatDateForCreatorsPaymentsTables(payableJournal.createdAt);
      }
      return '-';
    },
    sort: (record) => {
      if (record.paymentDate) {
        return record.paymentDate;
      }
      if (!record.paymentDate && record.payableJournals && record.payableJournals.length > 0) {
        const payableJournal = record.payableJournals[record.payableJournals.length - 1];
        return payableJournal.createdAt;
      }
      return null;
    },
  },
  amount: {
    title: 'Amount',
    valueFunc: record => (WVFormatter.formatCurrency(record.amount || 0)),
    sort: record => (WVFormatter.formatCurrency(record.amount || 0)),
  },
  provider: {
    title: 'Method',
    valueFunc: (record) => {
      /* if (record.method && record.method === 'stripe') {
        return (
          <span className="text-success">
            <FontAwesomeIcon icon={['fab', 'cc-stripe']} size="2x" />
          </span>
        );
      }
      if (record.method && record.method === 'billCom') {
        return (
          <img src="/images/payments/billComGreen.png" style={{ width: '25px' }} className="img-fluid" alt="Bill.com" />
        );
      } */

      const methodRight = record.method && ['wire', 'check', 'venmo', 'billCom', 'stripe', 'other', 'payPal'].includes(record.method) ? record.method : 'other';
      const methods = getConstant('creator', 'paymentMethod', {});
      return (
        <Badge
          color="success"
          title={`Payment method: ${methods[methodRight]}`}
        >
          <div
            style={{
              height: '25px',
              width: '50px',
              backgroundImage: `url(/images/payments/${methodRight}.png)`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Badge>
      );
    },
    sort: record => (record.method ? record.method : ''),
    // <img src={`/images/payments/${record ? record.method : 'other'}.png`} style={{ height: '25px', width: 'auto' }} className="img-fluid" alt={record ? record.method : 'other'} />
  },
  description: {
    title: 'Description',
    valueFunc: record => (record.description ? record.description : ''),
    sort: record => (record.description ? record.description : ''),
  },
  brand: {
    title: 'Brand',
    valueFunc: record => record.advertiser || '',
    sort: record => record.advertiser,
  },
  // offerName: {
  //   title: 'Offer',
  //   valueFunc: record => record.offerName || '',
  //   sort: record => record.offerName,
  // },
};

class TransfersTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      payables: [],
      loading: false,
      billComIssue: null,
      stripeIssue: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  // METHODS
  fetchData() {
    this.setState({
      loading: true,
      billComIssue: null,
      stripeIssue: null,
    });

    axios.get('/api/creator/finance/get-transfers').then((response) => {
      const { payables, billComIssue, stripeIssue } = response.data;

      this.setState({
        payables,
        loading: false,
        billComIssue,
        stripeIssue,
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: false,
      });
    });
  }
  //

  render() {
    // const { isStripe } = this.props;
    const {
      loading, payables, stripeIssue, billComIssue,
    } = this.state;

    return (
      <div className="transfers-table">
        {billComIssue ? (
          <Alert color="warning" bordered icon isOpen={billComIssue !== null}>
            <div className="alert__icon"><CloseCircleOutlineIcon /></div>
            <div className="alert__content">
              <p><span className="bold-text">Warning!</span> {billComIssue}</p>
            </div>
          </Alert>
        ) : null}
        {stripeIssue ? (
          <Alert color="warning" bordered icon isOpen={stripeIssue !== null}>
            <div className="alert__icon"><CloseCircleOutlineIcon /></div>
            <div className="alert__content">
              <p><span className="bold-text">Warning!</span> {stripeIssue}</p>
            </div>
          </Alert>
        ) : null}
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        { !loading && payables?.length > 0 &&
          <Row>
            <MatTableForRedux
              dataArray={payables}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              defaultOrder={{
                order: 'desc',
                orderBy: 'date',
              }}
              rowsPerPage={10}
              hideToolbar
            />
          </Row>
        }
        { !loading && !payables?.length &&
          <Alert color="warning" isOpen>
            <div className="alert__content">
              <p className="mt-2">Creator has no payments or issue with your payment account</p>
            </div>
          </Alert>
        }
      </div>
    );
  }
}

/* TransfersTable.propTypes = {
  isStripe: PropTypes.bool.isRequired,
}; */

export default TransfersTable;
