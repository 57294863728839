import React, { PureComponent } from 'react';
import { axios } from 'ApiClient';
import NotificationsAlert from './Alert';

class Notifications extends PureComponent {
  constructor(props) {
    super(props);
    this.closeNotification = this.closeNotification.bind(this);
    this.state = { data: [] };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios.get('/api/creator/notifications').then((response) => {
      if (response.data.success && response.data.notifications) {
        this.setState({ data: response.data.notifications });
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  async closeNotification(notification) {
    try {
      await axios.post('/api/creator/hide-notification', { notificationId: notification._id });
    } catch (e) {
      console.log(e, this.props);
    }
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        {data.map(row => (
          <NotificationsAlert notification={row} key={row._id} closeAlert={this.closeNotification} />
        ))}
      </div>
    );
  }
}

export default Notifications;
