import React, { useState } from 'react';
import { Button, FormGroup, FormFeedback, Label, Input, Row, Col } from 'reactstrap';
// import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import SendIcon from 'mdi-react/SendIcon';
import { useDispatch } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import WVConstants from '../../../../../shared/helpers/WVConstants';
// import { initializeSession } from '../../../../App/store/store';
import { checkCreator } from '../../../../../redux/reducers/creator/Creator';
import { checkAgent } from '../../../../../redux/reducers/agent/Agent';

const SetupStripeAlert = ({ influencerOrAgent, type }) => {

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([])
  const [accountType, setaccountType] = useState('individual')
  const [firstName, setFirstName] = useState(influencerOrAgent.first_name)
  const [lastName, setLastName] = useState(influencerOrAgent.last_name)
  const [companyName, setCompanyName] = useState('')
  
  const [bankCountryCode, setBankCountryCode] = useState(null);
  const [alerts, setAlerts] = useState({
    bankCountryCode: false,
  });
  const dispatch = useDispatch();

  const processUSSetup = async () => {
    setLoading(true);
    console.log('start');
    await axios.get(`/api/${type}/notify-stripe-start`).catch((error) => {
      console.log(error);
    });

    let url = `/stripe/authorize?accountType=${accountType}`;
    if (accountType === 'individual') {
      url += `&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}`;
    } else {
      url += `&companyName=${encodeURIComponent(companyName)}`;
    }
    url += `&countryCode=${bankCountryCode}`;
    setLoading(false);
    window.location.replace(url);
  }


  const removeTempId = async () => {
    setLoading(true);
    const response = await axios.post(`/api/${type}/clear-stripe`);
    if (response.data.success === true && response.data[type]) {
      // dispatch(initializeSession({ influencerOrAgent, ...response.data[type] }));
      if (type === 'creator') dispatch(checkCreator(() => setLoading(false)));
      if (type === 'agent') dispatch(checkAgent(() => setLoading(false)));
    }
    
  }

  const processContinueSetup = ()  => {
    let url = `/stripe/authorize-continue/${type}/${influencerOrAgent._id}`;
    window.location.replace(url);
  }

  const handleAccountTypeChange = (event) => {
    setaccountType(event.target.value);
    setErrors([]);
  }

  const handleBankCountryChanged = (event) => {
    const countryCode = event.target.value;
    setBankCountryCode(countryCode);
    setErrors([]);
    setAlerts({
      bankCountryCode: !['us', 'gb', 'ca'].includes(countryCode.toLowerCase()),
    })
  }

  const handleInputChange = (event) => {
    const fieldName = event.target.name;
    const err = [...errors].filter(item => item !== fieldName);
    setErrors(err);
    if ( fieldName === 'firstName') setFirstName(event.target.value);
    if ( fieldName === 'lastName') setLastName(event.target.value);
    if ( fieldName === 'companyName') setCompanyName(event.target.value);
  }

  const validateErrors = () => {
    const errs = [];

    if (WVValidator.isEmpty(bankCountryCode)) {
      errs.push('bankCountryCode');
    }

    if (accountType === 'individual') {
      if (WVValidator.isEmpty(firstName)) {
        errs.push('firstName');
      }
      if (WVValidator.isEmpty(lastName)) {
        errs.push('lastName');
      }
    } else if (accountType === 'company') {
      if (WVValidator.isEmpty(companyName)) {
        errs.push('companyName');
      }
    }
    setErrors(errs);
    console.log(errs);
    return !errs.length;
  }

  const onSetupButton = () => {
    if (!validateErrors()) { return; }
    processUSSetup();
  }


  if(!influencerOrAgent) return null;

  if (influencerOrAgent.payment.stripe.accountIdTemp) {
    return (
      <Alert color="info" hideCloseButton bordered whiteBg icon>
        <div>
          <h4 className="alert-title mt-2 mb-3">Action Required: Stripe Account Incomplete</h4>
          <h5 className="mb-3">
          Your Stripe account setup was interrupted. Please click <strong>Continue</strong> to finish your account setup, otherwise click <strong>Start Over</strong> to create a new account.
          </h5>
          <Col xs="12" lg="auto">
            <Button
              color="primary"
              className="mt-2 mb-0 radius-button"
              onClick={() => processContinueSetup()}
              size="md"
              disabled={loading}
            >
              Continue
            </Button>
            <Button
              color="primary"
              className="mt-2 mb-0 radius-button"
              outline
              onClick={removeTempId}
              size="md"
              disabled={loading}
            >
              Start Over
            </Button>
          </Col>
          
        </div>
      </Alert>
    );
  }

  return (
    <Alert color="info" hideCloseButton bordered whiteBg icon>
      <div>
        <h4 className="alert-title mt-2 mb-3">Action Required! Setup your payment account</h4>
        <h5 className="mb-3">
          We use
          <a href="https://stripe.com" target="_blank" rel="noopener noreferrer"> Stripe </a>
          to make sure you get paid on time and to keep your personal bank and details secure.
          Click <strong>Setup Account</strong> to set up your payments on Stripe.
        </h5>
        <Row className="align-items-center text-secondary">
          <Col xs="12" lg="auto" className="px-4">
            <FormGroup tag="fieldset">
              <Label className="font-weight-bold">Account Type</Label>
              <FormGroup className="mb-1" check>
                <Label check>
                  <Input
                    type="radio"
                    name="accountTypeRadio"
                    value="individual"
                    className="mt-1"
                    checked={accountType === 'individual'}
                    onChange={handleAccountTypeChange}
                  />
                  {' '}
                  Personal
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="accountTypeRadio"
                    value="company"
                    className="mt-1"
                    checked={accountType === 'company'}
                    onChange={handleAccountTypeChange}
                  />
                  {' '}
                  Company
                </Label>
              </FormGroup>
              { errors.length > 0 && <div className="py-2" /> }
            </FormGroup>
          </Col>
          <Col xs="12" lg="3">
            <FormGroup>
              <Label className="font-weight-bold">Bank Location</Label>
              <Input
                id="bankLocationSelect"
                type="select"
                bsSize="lg"
                defaultValue="defaultCountryCode"
                onChange={handleBankCountryChanged}
                invalid={errors.includes('bankCountryCode')}
              >
                <option value="defaultCountryCode" disabled>Select Country</option>
                {
                  Object.keys(WVConstants.countries()).map(code => (
                    <option value={code} key={code}>{WVConstants.countries()[code]}</option>
                  ))
                }
              </Input>
              <FormFeedback>Please select your bank location</FormFeedback>
              { errors.length > 0 && !errors.includes('bankCountryCode') && <div className="py-2" /> }
            </FormGroup>
          </Col>
          <Col>
            {
              accountType === 'individual' ?
                <Row>
                  <Col xs="12" lg="6">
                    <FormGroup>
                      <Label className="font-weight-bold" for="firstNameField">First Name</Label>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstNameField"
                        bsSize="lg"
                        value={firstName}
                        invalid={errors.includes('firstName')}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <FormFeedback>Please provide your First Name</FormFeedback>
                      { errors.length > 0 && !errors.includes('firstName') && <div className="py-2" /> }
                    </FormGroup>
                  </Col>
                  <Col xs="12" lg="6">
                    <FormGroup>
                      <Label className="font-weight-bold" for="lastNameField">Last Name</Label>
                      <Input
                        type="text"
                        name="lastName"
                        id="lastNameField"
                        bsSize="lg"
                        value={lastName}
                        invalid={errors.includes('lastName')}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <FormFeedback>Please provide your Last Name</FormFeedback>
                      { errors.length > 0 && !errors.includes('lastName') && <div className="py-2" /> }
                    </FormGroup>
                  </Col>
                </Row>
                :
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold" for="companyNameField">Company Name</Label>
                      <Input
                        type="text"
                        name="companyName"
                        id="companyNameField"
                        bsSize="lg"
                        value={companyName}
                        invalid={errors.includes('companyName')}
                        onChange={handleInputChange}
                      />
                      <FormFeedback>Please provide Company Name</FormFeedback>
                      { errors.length > 0 && !errors.includes('companyName') && <div className="py-2" /> }
                    </FormGroup>
                  </Col>
                </Row>
            }
          </Col>
          <Col xs="12" lg="auto">
            <Button
              color="primary"
              className="mt-2 mb-0 radius-button"
              onClick={onSetupButton}
              size="md"
              disabled={!(bankCountryCode && ['us', 'gb', 'ca'].includes(bankCountryCode.toLowerCase())) || loading}
            >
              Setup Account &nbsp;<SendIcon style={{marginTop: '-3px'}}/>{' '}
            </Button>
            { errors.length > 0 && <div className="py-2" /> }
          </Col>
        </Row>
        {
          alerts.bankCountryCode &&
          <Alert key={bankCountryCode} color="warning" bordered icon>
            <p>
              Please be aware that our current payment system, Stripe, does not yet support your country.
              However, we offer an alternative solution to meet your needs. Reach out to our dedicated support team at <a href="mailto:customersuccess@influencelogic.com">customersuccess@influencelogic.com</a>,
              informing them of your location and the country where your bank operates. Our team will promptly assist you with the necessary steps to ensure a smooth and efficient payment process tailored to your region.
            </p>
          </Alert>
        }
      </div>
    </Alert>
  );

}

export default SetupStripeAlert;
