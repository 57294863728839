import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Panel from '../../../shared/components/Panel';
import Breadcrumb from '../../../shared/components/BreadCrumbs';
import MediaContent from './components/MediaContent';

class MyContent extends React.Component {
  componentDidMount() {
    document.title = 'My Content';
  }

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumb
              links={[
                { title: 'Home', path: '/' },
                { title: 'My Content', path: null, className: 'last-item-color' },
              ]}
              isBackButton
              backButtonUrl="/"
            />
          </Col>
        </Row>
        <div className="documents-section">
          <Row>
            <Col md={12} className="my-3">
              <h3 className="documents-title">My Content</h3>
            </Col>
            <Panel showButtons={false} panelClass="panel-no-vertical-padding">
              <MediaContent />
            </Panel>
          </Row>
        </div>
      </Container>
    );
  }
}

export default MyContent;
