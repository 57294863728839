import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment';
import Panel from '../../../../shared/components/Panel';
import formater from '../../../../shared/helpers/WVFormatter';

const PayoutModal = () => {
  const session = useSelector(state => state.session);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [loadingPayout, setLoadingPayout] = useState(false);
  const [payments, setPayments] = useState(0);
  const [payout, setPayout] = useState(0);
  const [hasCpaOrHybridDeal, setHasCpaOrHybridDeal] = useState(false);

  useEffect(() => {
    setLoadingPayout(true);
    const dateRange = `dateTo=${moment().startOf('day').format('YYYY-MM-DD')}&dateFrom=${moment().startOf('day').subtract(2, 'years').format('YYYY-MM-DD')}`;
    axios.get(`/api/creator/dashboard/get-stats?${dateRange}`).then((res) => {
      setPayout(res.data.data.payout || 0);
      setLoadingPayout(false);
    }).catch((error) => {
      console.error(error);
    });

    setLoadingPayments(true);
    axios.get('/api/creator/finance/get-transfers').then((res) => {
      let total = 0;
      res.data.payables.forEach((d) => {
        total += Number(d.amount || 0);
      });
      setPayments(total);
      setLoadingPayments(false);
    }).catch((error) => {
      console.error(error);
    });

    axios({
      method: 'post',
      url: '/api/creator/get-creator',
      data: {
        authCode: session.authCode,
      },
    }).then((response) => {
      if (response.data.success && response.data.deals) {
        const test4CpaOrHybrid = response.data.deals.find(d => d.dealTerms.type_of_deal === 'Hybrid' || d.dealTerms.type_of_deal === 'CPA/Ambassador');
        if (test4CpaOrHybrid && test4CpaOrHybrid.length > 0) {
          setHasCpaOrHybridDeal(true);
        }
      }
    });

    return () => {};
  }, []);

  return (
    <Panel showButtons={false} md={12} panelClass="panel-no-vertical-padding">
      <Row className="creatorPaymentsPage">
        <Col sm={3} md={6} lg={6}>
          <div className="payout">
            {loadingPayout ?
              <div className="spinner-border il-text-gray mt-3" role="status" /> :
              <div className="dashboard__carousel-title">
                {formater.formatCurrency(payout)}
              </div>
                }
            <h5 className="">Payout</h5>
          </div>
        </Col>
        <Col sm={3} md={6} lg={6}>
          <div className="payments">
            {loadingPayments ?
              <div className="spinner-border il-text-gray mt-3" role="status" /> :
              <div className="dashboard__carousel-title">
                {formater.formatCurrency(payments)}
              </div>
              }
            <h5 className="">Payments</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          {
          (!loadingPayout && hasCpaOrHybridDeal) &&
          <p style={{ marginTop: '15px' }}>Please note: &quot;Payout&quot; may include conversions to be paid out during our next billing cycle.
            &quot;Payout&quot; includes payouts from conversions only.
            &quot;Payout&quot; includes max 24 months of conversions activity.
          </p>
        }
        </Col>
      </Row>
    </Panel>
  );
};

export default PayoutModal;
