import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  // CardBody,
  Spinner,
} from 'reactstrap';
import { axios } from 'ApiClient';
import mapMediaTypeIcons from '../../../admin/Scorecard/MediaContent/components/custom/mapMediaTypeIcons';
import DetailsCellValue from '../../../admin/Influencer/Influencer/influencerProfile/components/DetailsCellValue';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import '../../../admin/Influencer/Influencer/influencerProfile/components/tableStyling.css';
import Alert from '../../../../shared/easydev2/shared/components/Alert';

const mapType = mapMediaTypeIcons();

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: true,
  sortBy: 'publishDate',
  direction: 'desc',
  placeholder: 'Search Details',
  searchFieldMinWidth: '50%',
};

const MediaContent = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      axios({
        method: 'get',
        url: '/api/creator/get-content?filterCompliant=true',
      }).then((response) => {
        if (response?.data?.success && response.data?.records) {
          setContent(response.data.records);
        }
        setLoading(false);
      });
    }

    fetchData();
  }, []);

  const columnTransform = useMemo(() => [
    {
      id: 'title',
      Header: 'Details',
      Cell: value => <DetailsCellValue record={value.row.original} />,
      accessor: record => record.title?.toLowerCase(),
    },
    {
      id: 'type',
      Header: 'Type',
      Cell: value => <img style={{ width: '30px' }} src={`/images/mediacontent/${mapType[value.row.original?.type]}.png`} alt={value.row.original.type} title={value.row.original.type} />,
      accessor: record => record.type,
    },
    {
      id: 'publishDate',
      Header: 'Publish Date',
      Cell: value => (value.row.original.publishDate ? moment.utc(value.row.original.publishDate).local().format('MM/DD/YYYY') : ''),
      accessor: record => (record.publishDate ? moment.utc(record.publishDate).local().format('MM/DD/YYYY') : ''),
    },
  ]);
  if (!loading && content.length === 0) {
    return (
      <Alert color="warning" bordered icon>
        <p className="py-2">
          No Media Content
        </p>
      </Alert>);
  }

  return (
    <Card id="mediaConentBlock" style={{ padding: 0 }}>
      <div>
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {!loading && content.length > 0 && (
          <ReactTableBase
            columns={columnTransform}
            data={content}
            tableConfig={tableConfig}
          />
        )}
      </div>
    </Card>
  );
};

export default MediaContent;
