import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import OffersSummary from './OffersSummary';
import PayoutModal from './PayoutModal';
import Summary from './Summary';
import { fetchData } from '../../../../../redux/reducers/creator/Creator';

class SummaryPanel extends PureComponent {
  static propTypes = {
    dateFrom: PropTypes.number.isRequired,
    dateTo: PropTypes.number.isRequired,
    // session: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatch: PropTypes.func.isRequired,
    payout: PropTypes.number.isRequired,
    clicksCount: PropTypes.number.isRequired,
    conversionsCount: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    offers: PropTypes.arrayOf(PropTypes.any).isRequired,
    haveUpfront: PropTypes.bool.isRequired,
    selectedButton: PropTypes.string.isRequired,
    lastFetched: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.showPayoutModal = this.showPayoutModal.bind(this);
    this.state = {
      showPayoutModal: false,
    };
  }

  componentDidMount() {
    const {
      dateFrom, dateTo, dispatch, lastFetched,
    } = this.props;
    if (!lastFetched || moment(lastFetched).diff(moment(), 'seconds') < -30) {
      dispatch(fetchData({ dateFrom, dateTo }));
    }
  }
  componentDidUpdate(prevProps) {
    const {
      dateFrom, dateTo, dispatch, selectedButton,
    } = this.props;
    if ((prevProps.selectedButton !== selectedButton && selectedButton !== 'custom') || (selectedButton === 'custom' && (prevProps.dateFrom !== dateFrom || prevProps.dateTo !== dateTo))) {
      dispatch(fetchData({ dateFrom, dateTo }));
    }
  }

  showPayoutModal() {
    this.setState({
      showPayoutModal: true,
    });
  }

  render() {
    const {
      showPayoutModal,
    } = this.state;
    const {
      isFetching,
      offers,
      haveUpfront,
      payout,
      clicksCount,
      conversionsCount,
    } = this.props;
    if (haveUpfront && offers.length === 0) {
      return (<div>You are not currently enrolled in any sponsorship programs with referral payouts. If you are interested in learning more about these programs, please contact your CSP. Thanks!</div>);
    }
    return (
      <div>
        <Summary
          isFetching={isFetching}
          offers={offers}
          payout={payout}
          clicksCount={clicksCount}
          conversionsCount={conversionsCount}
        />
        <OffersSummary
          isFetching={isFetching}
          offers={offers}
        />
        <PayoutModal
          isOpen={!!showPayoutModal}
          toggleModal={() => this.setState({ showPayoutModal: false })}
          showPayouts
        />
      </div>
    );
  }
}

export default connect(state => ({
  session: state.session,
  payout: state.creatorCreatorReducer.payout,
  clicksCount: state.creatorCreatorReducer.clicksCount,
  conversionsCount: state.creatorCreatorReducer.conversionsCount,
  isFetching: state.creatorCreatorReducer.loading.total,
  offers: state.creatorCreatorReducer.offers,
  haveUpfront: state.creatorCreatorReducer.haveUpfront,
  selectedButton: state.creatorCreatorReducer.selectedButton,
  lastFetched: state.creatorCreatorReducer.lastFetched,
}))(SummaryPanel);
