import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import formater from '../../../../shared/helpers/WVFormatter';

class SummaryPanel extends PureComponent {
  static propTypes = {
    selectedOffer: PropTypes.objectOf(PropTypes.any),
    isFetching: PropTypes.bool.isRequired,
    offers: PropTypes.arrayOf(PropTypes.any).isRequired,
  };
  static defaultProps = {
    selectedOffer: null,
  }
  render() {
    const {
      isFetching,
      offers,
      selectedOffer,
    } = this.props;
    if (selectedOffer && selectedOffer.haveUpfront && offers.length === 0) {
      return (<div>You are not currently enrolled in any sponsorship programs with referral payouts. If you are interested in learning more about these programs, please contact your CSP. Thanks!</div>);
    }
    return (
      <div className="offer-details-dashboard">
        <Row>
          <Col className="dashboard-top-card payout mb-3" md="6" lg="4">
            <div className="payout">
              {!selectedOffer || isFetching ?
                <div className="spinner-border il-text-gray mt-3" role="status" />
                :
                <div className="dashboard__carousel-title">
                  {formater.formatCurrency(selectedOffer.payout)}
                </div>}
              <h4 className="">Payout</h4>
            </div>
          </Col>
          <Col className="dashboard-top-card clicks mb-3" md="6" lg="4">
            <div className="clicks">
              {!selectedOffer || isFetching ?
                <div className="spinner-border il-text-gray mt-3" role="status" />
                    :
                <div className="dashboard__carousel-title">
                  {formater.formatIntNumber(selectedOffer.clicks)}
                </div>}
              <h4 className="">Clicks</h4>
            </div>
          </Col>
          <Col className="dashboard-top-card mb-3" md="6" lg="4">
            <div className="conversions">
              {!selectedOffer || isFetching ?
                <div className="spinner-border il-text-gray mt-3" role="status" />
                    :
                <div className="dashboard__carousel-title">
                  {formater.formatIntNumber(selectedOffer.conversions)}
                </div>}
              <h4 className="">Conversions</h4>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(state => ({
  session: state.session,
}))(SummaryPanel);
