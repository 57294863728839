import React from 'react';
import {
  Row, Card, CardBody, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../../shared/helpers/WVFormatter';
import TopButtonsPeriod from '../TopButtonsPeriod';

const Summary = ({
  payout, clicksCount, conversionsCount, isFetching,
}) => (
  <Card >
    <CardBody >
      {/* <h4 className="il-color-deep-blue font-weight-bold">
        Summary
      </h4 --> */}
      <TopButtonsPeriod>
        <h3 className="il-color-deep-blue font-weight-bold mt-1 summary-title">
          Summary:
        </h3>
      </TopButtonsPeriod>
      <Row>
        <Col className="dashboard-top-card mb-3" md="6" lg="4">
          <div className="payout">
            {isFetching ?
              <div className="spinner-border il-text-gray mt-3" role="status" /> :
              <>
                <div className="dashboard__carousel-title">
                  {formater.formatCurrency(payout)}
                </div>
                <h5 className="">Payout</h5>
              </>
            }
          </div>
        </Col>
        <Col className="dashboard-top-card clicks mb-3" md="6" lg="4">
          <div className="clicks">
            {isFetching ? <div className="spinner-border il-text-gray mt-3" role="status" /> :
            <>
              <div className="dashboard__carousel-title">{formater.formatIntNumber(clicksCount)}</div>
              <h5 className="">Clicks</h5>
            </>
            }
          </div>
        </Col>
        <Col className="dashboard-top-card mb-3" md="6" lg="4">
          <div className="conversions">
            {isFetching ? <div className="spinner-border il-text-gray mt-3" role="status" /> :
            <>
              <div className="dashboard__carousel-title">{formater.formatIntNumber(conversionsCount)}</div>
              <h5 className="">Conversions</h5>
            </>
            }
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

Summary.propTypes = {
  payout: PropTypes.number.isRequired,
  clicksCount: PropTypes.number.isRequired,
  conversionsCount: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default Summary;
