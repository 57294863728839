import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StepStatus = ({ onboarding, step }) => {
  const stepStatus = onboarding[`step${step}Status`];
  const previousStepStatus = onboarding[`step${step - 1}Status`];
  console.log(stepStatus, previousStepStatus);
  let color = 'success';
  let text = '';
  let icon = (<FontAwesomeIcon icon={['fa', 'check-circle']} />);
  if (stepStatus === 'pending') {
    text = 'To Be Completed';
    icon = (<FontAwesomeIcon icon={['fa', 'tasks']} />);
    color = 'danger';
  }
  /* if (stepStatus === 'pending' && previousStepStatus && previousStepStatus === 'pending') {
    text = 'Incomplete';
    icon = (<FontAwesomeIcon icon={['fa', 'minus-circle']} />);
    color = 'danger';
  } */
  return (
    <span className={`float-right text-${color} card-info-label`}>{icon}{' '}{text}</span>
  );
};
StepStatus.propTypes = {
  onboarding: PropTypes.objectOf(PropTypes.any).isRequired,
  step: PropTypes.number.isRequired,
};

export default StepStatus;
