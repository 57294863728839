import React, { PureComponent } from 'react';
import { Spinner, Card, CardBody, Col, Row, Button, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import ReactPlayer from 'react-player';
import { axios } from 'ApiClient';
import CopyToClipboard from '../../../../shared/components/CopyToClipboard';
// import { Link } from 'react-router-dom';

class BrandResourcePage extends PureComponent {
  static propTypes = {
    brandId: PropTypes.string.isRequired,
    // influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: {
        resource: false,
      },
      resource: false,
      modalOpened: false,
      videoUrlInModal: false,
      videoTitleInModal: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.fetchBrandResourceInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.brandId !== prevProps.brandId) {
      this.fetchBrandResourceInfo();
    }
  }

  fetchBrandResourceInfo() {
    this.setState({
      loading: {
        resource: true,
      },
    });

    axios.get(`/api/creator/resource/get-resource?id=${this.props.brandId}`).then((response) => {
      const { resource } = response.data;
      this.setState({
        resource: resource || false,
        loading: {
          resource: false,
        },
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: {
          resource: false,
        },
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  previewItem(file) {
    if (file.fileType === 'url') {
      if (file.fileUrlExternal.indexOf('drive.google.com') === -1) {
        this.setState({
          modalOpened: true,
          videoUrlInModal: file.fileUrlExternal,
          videoTitleInModal: file.title,
        });
      } else {
        window.open(file.fileUrlExternal);
      }
    } else {
      window.open(file.fileUrl);
    }
  }
  // eslint-disable-next-line class-methods-use-this
  downloadItem(file) {
    window.open(`/api/creator/resource/download-resource?filePath=${file.filePath}&download=1`);
  }

  closeModal() {
    this.setState({
      modalOpened: false,
      videoUrlInModal: false,
      videoTitleInModal: false,
    });
  }

  renderFiles(itemsToMap) {
    const supportedIcons = ['avi', 'doc', 'docx', 'gif', 'jpeg', 'jpg', 'mkv', 'mp3', 'mp4', 'pdf', 'png', 'ppt', 'pptx', 'rar', 'txt', 'wav', 'xls', 'xlsx', 'zip'];
    return (
      <Card style={{ height: 'auto', paddingBottom: 0 }}>
        <CardBody style={{ height: 'auto', paddingBottom: 0 }}>
          {itemsToMap.map(file => (
            <Row key={uid(file.title + file.fileUrl)} style={{ margin: '10px 0 10px 0' }}>
              <Col md={1} xs={3} style={{ textAlign: 'center' }}>
                {/* eslint-disable-next-line max-len */}
                <img style={{ width: 'auto', maxHeight: '40px' }} src={file.fileType === 'url' ? '/images/resources/icons/avi.png' : `/images/resources/icons/${supportedIcons.includes(file.fileUrl.split('.')[file.fileUrl.split('.').length - 1]) ? file.fileUrl.split('.')[file.fileUrl.split('.').length - 1] : 'other'}.png`} alt="" />
              </Col>
              <Col md={8} xs={9}>
                <div><b>{file.title}</b></div>
                <div>{file.description}</div>
              </Col>
              <Col md={3} xs={12}>
                <Button
                  color="primary"
                  size="sm"
                  style={{ width: '140px' }}
                  onClick={() => { this.previewItem(file); }}
                  className="mt-sm-0 mt-3"
                >
                  <span className="lnr lnr-eye" />
                  &nbsp;Preview
                </Button>
                {file.fileType !== 'url' && (
                  <Button
                    color="primary"
                    size="sm"
                    style={{ width: '140px' }}
                    onClick={() => { this.downloadItem(file); }}
                  >
                    <span className="lnr lnr-download" />
                    &nbsp;Download
                  </Button>
                )}
              </Col>
            </Row>))}
        </CardBody>
      </Card>);
  }

  render() {
    const {
      loading,
      resource,
      modalOpened,
      videoUrlInModal,
      videoTitleInModal,
    } = this.state;
    const { deal } = resource;
    let resourceVideo = false;
    if (typeof resource.videoType === 'undefined' || resource.videoType === 'upload') {
      if (resource.videoUrl) {
        resourceVideo = resource.videoUrl;
      }
    }
    if (resource.videoType === 'url') {
      if (resource.videoUrlExternal) {
        resourceVideo = resource.videoUrlExternal;
      }
    }
    return (
      <Card>
        <CardBody style={{ backgroundColor: 'transparent' }}>
          {loading.resource &&
          (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )
          }
          {!loading.resource && resource &&
          (
            <>
              <Row>
                <Col md={6} xs={12}>
                  {resource.logoUrl && (<img src={resource.logoUrl} alt="" style={{ maxWidth: '400px' }} />)}
                  {!resource.logoUrl && resource.brandObj.logoUrl && (<img src={resource.brandObj.logoUrl} alt="" style={{ maxWidth: '400px' }} />)}
                  {deal && deal.dealTerms.referral_url ?
                    (
                      <CopyToClipboard value={deal.dealTerms.referral_url || ''} className="d-block mt-2">
                        <span className="il-text-gray">
                          <span className="fa fa-link" />{' '}
                          <span className="il-text-gray">
                            {deal.dealTerms.referral_url}
                          </span>
                        </span>
                      </CopyToClipboard>
                      ) : null
                  }
                  {deal && deal.dealTerms.coupon_code ?
                    (
                      <CopyToClipboard value={deal.dealTerms.coupon_code || ''} className="d-block mt-2">
                        <span className="il-text-gray">
                          <span className="fa fa-tag" />{' '}
                          <span className="il-text-gray">
                            {deal.dealTerms.coupon_code}
                          </span>
                        </span>
                      </CopyToClipboard>
                    ) : null
                  }
                  <h1 style={{ margin: '10px 0' }}>Welcome</h1>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: resource.introText }} />
                </Col>
                <Col md={6} xs={12}>
                  {resourceVideo && (<ReactPlayer url={resourceVideo} controls style={{ maxWidth: '100%' }} className="mt-sm-0 mt-3" />)}
                </Col>
              </Row>
              {resource.education && resource.education.length > 0 && (
                <Row>
                  <Col>
                    <br /><br />
                    <h2><img style={{ width: '45px', margin: '-5px 10px 0 0' }} src="/images/resources/resource-education.png" alt="" />Education</h2>
                    <br />
                    {this.renderFiles(resource.education)}
                  </Col>
                </Row>)}

              {resource.brandGuidelines && resource.brandGuidelines.length > 0 && (
                <Row>
                  <Col>
                    <br /><br />
                    <h2><img style={{ width: '45px', margin: '-5px 10px 0 0' }} src="/images/resources/resource-brand-guidelines.png" alt="" />Brand Guidelines</h2>
                    <br />
                    {this.renderFiles(resource.brandGuidelines)}
                  </Col>
                </Row>)}

              {resource.creativeAssets && resource.creativeAssets.length > 0 && (
                <Row>
                  <Col>
                    <br /><br />
                    <h2><img style={{ width: '45px', margin: '-5px 10px 0 0' }} src="/images/resources/resource-creative-assets.png" alt="" />Creative Assets</h2>
                    <br />
                    {this.renderFiles(resource.creativeAssets)}
                  </Col>
                </Row>)}
            </>
          )}
        </CardBody>
        <Modal
          style={{ minWidth: '640px' }}
          isOpen={modalOpened}
          toggle={this.closeModal}
        >
          <div className="modal__header">
            <h4 className="text-modal  modal__title">{videoTitleInModal}</h4>
          </div>
          <div className="modal__body">
            {videoUrlInModal && (<ReactPlayer url={videoUrlInModal} controls style={{ maxWidth: '100%' }} />)}
            <br />
          </div>
          <div className="modal__footer">
            <Button
              color="primary"
              size="sm"
              style={{ width: '140px' }}
              onClick={() => { this.closeModal(); }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </Card>
    );
  }
}

export default BrandResourcePage;
