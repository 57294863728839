/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const creatorPayoutReducer = createSlice({
  name: 'creator_payout',
  initialState: {
    loading: {
      offers: false,
    },
    offers: [],
    error: null,
    lastFetched: new Date(),
  },
  reducers: {
    getOffersStart(state) {
      // console.log(state);
      state.loading = {
        ...state.loading,
        offers: true,
      };
      state.offers = [];
    },
    getOffersError(state) {
      // console.log(state, action);
      state.loading = {
        ...state.loading,
        offers: false,
      };
      state.offers = [];
    },
    getOffersSuccess(state, action) {
      const { offers } = action.payload;
      state.loading = {
        ...state.loading,
        offers: false,
      };
      state.offers = offers;
      state.error = null;
    },
  },
});
const getOffersApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/creator/finance/get-offers');
};

export const {
  getOffersStart,
  getOffersError,
  getOffersSuccess,
} = creatorPayoutReducer.actions;

export const getOffersWithPayables = callback => async (dispatch) => {
  try {
    dispatch(getOffersStart());
    const response = await getOffersApi();
    dispatch(getOffersSuccess({
      offers: response.data.offers,
    }));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getOffersError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};


export default creatorPayoutReducer.reducer;
