/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import TopbarMenuLink from './TopbarMenuLink';
import { formatString } from '../../../../shared/helpers/WVFormatter';
// import ViewHelpers from 'admin-bro/lib/backend/utils/view-helpers';

// import { UserProps } from '../../../shared/prop-types/ReducerProps';
// import { hookAuth0 } from '../../../shared/components/auth/withAuth0';

const Ava = '/images/user/defaultAvatar.png';

class TopbarProfile extends PureComponent {
  static propTypes = {
    // user: UserProps.isRequired,
    // eslint-disable-next-line
    session: PropTypes.object.isRequired,
    // eslint-disable-next-line
    paths: PropTypes.object.isRequired,
  }


  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = () => {
    localStorage.removeItem('easydev');
  }

  render() {
    const { session } = this.props;
    const { collapse } = this.state;

    // const h = new ViewHelpers();

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={session && (session.logo || Ava)} alt="avatar" />
          <p className="topbar__avatar-name">{formatString(session && session.first_name)} {formatString(session && session.last_name)}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink
              title="My Profile"
              icon="user"
              path={
                h.recordActionUrl({
                  resourceId: 'AdminUser',
                  recordId: `${session._id}`,
                  actionName: 'edit',
                })
              }
              onClick={this.toggle}
            /> */}
            <TopbarMenuLink
              title="Account Settings"
              icon="cog"
              path="/settings"
              onClick={this.toggle}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/logout"
              onClick={() => window.location.href = '/logout'}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default TopbarProfile;
