/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { unflatten } from 'flat';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const notificationReducer = createSlice({
  name: 'advertiser',
  initialState: {
    loading: {
      item: false,
      usersToNotifications: false,
      users: false,
      updating: false,
    },
    error: null,
    usersToNotifications: [],
    users: [],
    lastFetched: new Date(),
    notification: {},
  },
  reducers: {
    getStartLoading(state) {
      state.loading = {
        ...state.loading,
        usersToNotifications: true,
        users: true,
      };
      state.usersToNotifications = [];
      state.users = [];
    },
    getStartUpdating(state) {
      state.loading = {
        ...state.loading,
        updating: true,
      };
    },
    getEndUpdating(state) {
      state.loading = {
        ...state.loading,
        updating: false,
      };
    },
    getError(state, action) {
      console.log(action);
    },
    getSuccess(state, action) {
      const { usersToNotifications, users } = action.payload;
      state.loading = {
        ...state.loading,
        usersToNotifications: false,
        users: false,
      };
      const newUsers = [];
      users.forEach((userf) => {
        const user = unflatten(userf);
        const findUserf = usersToNotifications.find(selectedUser => selectedUser.params.creator === user.params._id);
        const findUser = unflatten(findUserf);
        // console.log(findUser);
        user.selected = !!findUser;
        const selectedNotification = findUser ? findUser.params.notifications.find(n => n.notification === state.notification.id) : null;
        user.selectedNotification = selectedNotification;
        newUsers.push(user);
      });
      state.usersToNotifications = usersToNotifications;
      state.users = newUsers;
      state.error = null;
      state.lastFetched = new Date();
    },
    getStartLoadingNotification(state) {
      state.loading = {
        ...state.loading,
        item: true,
      };
      state.notification = {};
      state.error = null;
    },
    getNotificationSuccess(state, action) {
      const { notification } = action.payload;
      state.loading = {
        ...state.loading,
        item: false,
      };
      state.notification = notification;
      state.error = null;
    },
    updateCreatorNotificationsBulk(state, action) {
      // console.log(action);
      const { usersToNotifications, actionUpdate, ids } = action.payload;
      const newUsers = [];
      state.users.forEach((userf) => {
        const user = unflatten(userf);
        if (!ids.includes(user.id)) {
          newUsers.push(user);
          return;
        }
        if (actionUpdate === 'show') {
          const findUserf = usersToNotifications.find(selectedUser => selectedUser.params.creator === user.params._id);
          const findUser = unflatten(findUserf);
          if (findUserf) {
            user.selected = !!findUser;
            const selectedNotification = findUser ? findUser.params.notifications.find(n => n.notification === state.notification.id) : null;
            user.selectedNotification = selectedNotification;
          }
        }
        if (actionUpdate === 'hide') {
          user.selected = false;
          user.selectedNotification = null;
        }
        newUsers.push(user);
      });
      state.usersToNotifications = usersToNotifications;
      state.users = newUsers;
      state.error = null;
      state.lastFetched = new Date();
    },
    updateCreatorNotification(state, action) {
      // console.log(action);
      const { creator, creatorNotification, actionUpdate } = action.payload;
      const newUsers = [];
      state.users.forEach((user) => {
        if (user.id === creator.id) {
          if (actionUpdate === 'show') {
            user.selected = true;
            const selectedNotification = creatorNotification.params.notifications.find(n => n.notification === state.notification.id);
            user.selectedNotification = selectedNotification;
          }
          if (actionUpdate === 'hide') {
            user.selected = false;
            user.selectedNotification = null;
          }
        }
        // console.log(user);
        newUsers.push(user);
      });
      // state.usersToNotifications = usersToNotifications;
      state.users = newUsers;
      state.lastFetched = (new Date()).toString();
    },
  },
});
const getNotificationUsersApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/notification/get-notification-users?id=${id}`);
};

export const {
  getStartLoading,
  getSuccess,
  getError,
  getStartUpdating,
  getEndUpdating,

  getNotificationSuccess,
  getStartLoadingNotification,
  updateCreatorNotification,
  updateCreatorNotificationsBulk,
} = notificationReducer.actions;

export const fillUsers = id => async (dispatch) => {
  try {
    dispatch(getStartLoading());
    const response = await getNotificationUsersApi(id);
    dispatch(getSuccess({
      usersToNotifications: response.data.usersToNotifications,
      users: response.data.users,
    }));
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
    console.log(err);
  }
};
export const getNotification = id => async (dispatch) => {
  try {
    dispatch(getStartLoadingNotification());
    const api = new ApiClient();
    const notification = await api.recordAction({
      resourceId: 'PortalNotification',
      recordId: id,
      actionName: 'show',
    });
    if (notification.data && notification.data.record && notification.data.record.id) {
      dispatch(getNotificationSuccess({
        notification: unflatten(notification.data.record),
      }));
    } else {
      throw new Error('Unable to get Record');
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
// eslint-disable-next-line func-names
export const updateNotificationShow = async function ({ notificationId, ids, value }) {
  const api = new ApiClient();
  const response = await api.client.post('/api/portal/notification/post-bulk-edit', { notificationId, ids, value });
  if (response.data && response.data.success) {
    return unflatten(response.data);
  }
  throw new Error('There was an error fetching records, Check out console to see more information.');
};

// eslint-disable-next-line func-names
export const updateNotificationUserShow = async function ({ notificationId, ids, value }) {
  const api = new ApiClient();
  const response = await api.client.post('/api/portal/notification/post-user-bulk-edit', { notificationId, ids, value });
  if (response.data && response.data.success) {
    return unflatten(response.data);
  }
  throw new Error('There was an error fetching records, Check out console to see more information.');
};

export const editNotificationCreatorsBulk = (notificationId, ids, value, callback) => async (dispatch) => {
  try {
    dispatch(getStartUpdating());
    const response = await updateNotificationShow({ notificationId, ids, value });
    dispatch(getEndUpdating());
    dispatch(updateCreatorNotificationsBulk({
      ids,
      usersToNotifications: response.creatorNotifications,
      actionUpdate: value,
    }));
    // dispatch(fillUsers(notificationId));
    if (callback) {
      callback(response.record);
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
    console.log(err);
  }
};
export default notificationReducer.reducer;
