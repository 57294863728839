import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import RefreshIcon from 'mdi-react/RefreshIcon';
import renderIntervalDatePicker from '../../../../shared/components/form/IntervalDatePicker';
import { setDates, setSelectedButton, fetchData } from '../../../../redux/reducers/creator/Creator';
import { getLast7Days } from '../../../../shared/helpers/Dates';

class TopButtonsPeriod extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedButton: PropTypes.string.isRequired,
    dateFrom: PropTypes.number.isRequired,
    dateTo: PropTypes.number.isRequired,
    children: PropTypes.element,
  };
  static defaultProps = {
    children: null,
  }
  constructor(props) {
    super(props);
    this.selectDateRange = this.selectDateRange.bind(this);
  }
  getText = () => {
    const { dateFrom, dateTo } = this.props;
    let dateText = '';
    if (
      moment.unix(dateFrom).format('M') === moment.unix(dateTo).format('M') &&
      moment.unix(dateFrom).format('D') === moment.unix(dateTo).format('D') &&
      moment.unix(dateFrom).format('YYYY') === moment.unix(dateTo).format('YYYY')) {
      dateText = moment.unix(dateFrom).format('MMM D, YYYY');
    } else {
      dateText = `${moment.unix(dateFrom).format('MMM D, YYYY')} - ${moment.unix(dateTo).format('MMM D, YYYY')}`;
    }
    return dateText;
  }
  selectDateRange = (action) => {
    const { dispatch } = this.props;
    let dateFrom;
    let dateTo;
    switch (action) {
      case 'month_to_date':
        dateFrom = moment().startOf('month').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().unix();
        break;
      case 'last_month':
        dateFrom = moment().startOf('month').subtract(1, 'day')
          .startOf('month')
          .set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          })
          .unix();
        dateTo = moment().startOf('month').subtract(1, 'day')
          .set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          })
          .unix();
        break;
      case 'yesterday':
        dateFrom = moment().subtract(1, 'day').startOf('day').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        })
          .unix();
        dateTo = moment().subtract(1, 'day').endOf('day').set({
          hour: 23, minute: 59, second: 59, millisecond: 999,
        })
          .unix();
        break;
      case 'today':
        dateFrom = moment().startOf('day').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().unix();
        break;
      case 'last_7_days':
        [dateFrom, dateTo] = getLast7Days();
        break;
      case 'custom':
        // eslint-disable-next-line prefer-destructuring
        dateFrom = this.props.dateFrom;
        // eslint-disable-next-line prefer-destructuring
        dateTo = this.props.dateTo;
        break;
      default:
        dateFrom = moment().startOf('month').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().unix();
        break;
    }
    dispatch(setDates({ dateFrom, dateTo }));
    dispatch(setSelectedButton(action));
  }

  refectchData = () => {
    const { dispatch, dateFrom, dateTo } = this.props;
    dispatch(fetchData({ dateFrom, dateTo }));
  }

  handleDatepickerChange = (event) => {
    let dateFrom = false;
    let dateTo = false;
    const { dispatch } = this.props;
    if (typeof event.start !== 'undefined') {
      dateFrom = moment(event.start).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).unix();
    }
    if (typeof event.end !== 'undefined') {
      dateTo = moment(event.end).set({
        hour: 23, minute: 59, second: 59, millisecond: 999,
      }).unix();
    }
    if (dateFrom && dateTo) {
      dispatch(setDates({ dateFrom, dateTo }));
    }
  }

  render() {
    const {
      isLoading, dateFrom, dateTo, selectedButton, children,
    } = this.props;
    const minDate = moment().subtract(2, 'years').set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).toDate();
    const maxDate = moment().set({
      hour: 23, minute: 59, second: 59, millisecond: 999,
    }).toDate();

    const buttonClass = 'btn-lg mr-1 creator-top-buttons';

    return (
      <Row className="mb-3">
        {children ? (<Col md="auto">{children}</Col>) : null}
        <Col>
          <Button
            onClick={() => this.selectDateRange('month_to_date')}
            disabled={isLoading}
            className={selectedButton === 'month_to_date' ? `active ${buttonClass}` : buttonClass}
          >
            Current Month
          </Button>
          <Button
            onClick={() => this.selectDateRange('last_month')}
            disabled={isLoading}
            className={selectedButton === 'last_month' ? `active ${buttonClass}` : buttonClass}
          >
            Last Month
          </Button>
          <Button
            onClick={() => this.selectDateRange('today')}
            disabled={isLoading}
            className={selectedButton === 'today' ? `active ${buttonClass}` : buttonClass}
          >
            Today
          </Button>
          <Button
            onClick={() => this.selectDateRange('last_7_days')}
            disabled={isLoading}
            className={selectedButton === 'last_7_days' ? `active ${buttonClass}` : buttonClass}
          >
            Last 7 Days
          </Button>
          <Button
            onClick={() => this.selectDateRange('custom')}
            // color="primary"
            disabled={isLoading}
            className={selectedButton === 'custom' ? `active ${buttonClass}` : buttonClass}
          >
            Custom
          </Button>
          { (!isLoading) && <RefreshIcon
            style={{ marginLeft: '10px', marginBottom: '15px' }}
            size={26}
            type="button"
            onClick={() => this.refectchData()}
          />}
          {this.props.selectedButton === 'custom' ?
            <div className="dpickers-div mb-3" style={{ width: '100%' }}>
              <Field
                name="interval_date"
                component={renderIntervalDatePicker}
                onChange={this.handleDatepickerChange}
                minDate={minDate}
                maxDate={maxDate}
                startDate={moment.unix(dateFrom).toDate()}
                endDate={moment.unix(dateTo).toDate()}
              />
            </div>
            : ''}
        </Col>
        <Col xs="12"><h5 style={{ color: 'black' }} className="">{this.getText()}</h5></Col>
      </Row>
    );
  }
}


export default connect(state => ({
  dateFrom: state.creatorCreatorReducer.dateFrom,
  dateTo: state.creatorCreatorReducer.dateTo,
  selectedButton: state.creatorCreatorReducer.selectedButton,
  isLoading: state.creatorCreatorReducer.loading.total,
}))(reduxForm({
  form: 'dashboard_form',
})(TopButtonsPeriod));
