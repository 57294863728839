/* eslint-disable no-return-assign */
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { axios } from 'ApiClient';
// import _ from 'lodash';
// import { initializeSession } from '../../App/store/store';
import { checkCreator } from '../../../redux/reducers/creator/Creator';


class CheckCreator extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // session: PropTypes.objectOf(PropTypes.any).isRequired,
    // history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  componentDidMount() {
    this.checkCreator();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  checkCreator = () => {
    const { dispatch } = this.props;
    dispatch(checkCreator());
    if (window.process.env.NODE_ENV !== 'local') {
      this.timer = setTimeout(this.checkCreator.bind(this), 30 * 1000);
    }
    /* axios({
      method: 'post',
      url: '/api/creator/get-creator',
      data: {
        authCode: session.authCode,
      },
    }).then((response) => {
      if (response.data.success === true && response.data.user) {
        const {
          user, deals, adminUser, contact, brandResources,
        } = response.data;
        user.deals = deals;
        user.adminUser = adminUser;
        user.contact = contact;
        user.brandResources = brandResources;
        if (!_.isEqual(user, session)) {
          dispatch(initializeSession(response.data.user));
        }
      } else {
        dispatch(initializeSession({}));
        history.push('/login');
      }
      if (process.env.NODE_ENV !== 'local') {

      }
    }).catch(() => {
      dispatch(initializeSession({}));
      history.push('/login');
    }); */
  };

  render() {
    return null;
  }
}

export default connect(state => ({
  session: state.session,
  
}))(CheckCreator);
