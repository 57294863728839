import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogInCreator from '../../creator/LogIn/index';
import LogInSecretCreator from '../../creator/LogInSecret/index';
import ForgotPassword from '../../creator/ForgotPassword/index';
import ResetPassword from '../../creator/ResetPassword/index';
import MainWrapper from '../MainWrapper';
import WrappedRoutesCreator from './WrappedRoutesCreator';

const RouterCreator = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/login" component={LogInCreator} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/reset/:token/:firstLogin" component={ResetPassword} />
        <Route path="/login-secret/:token" component={LogInSecretCreator} />
        <Route path="/" component={WrappedRoutesCreator} />
      </Switch>
    </main>
  </MainWrapper>
);

export default RouterCreator;
