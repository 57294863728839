import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import moment from 'moment-timezone';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import formater from '../../../../shared/helpers/WVFormatter';


// import { currentTimezoneName } from '../../../../shared/helpers/Dates';

const columnTransform = {
  date: {
    title: 'Date',
    valueFunc: record => (record.date ? formater.formatDateForCreatorsPaymentsTables(record.date) : '-'),
    sort: record => (record.dateOriginal),
  },
  payouts: {
    title: 'Payout',
    valueFunc: record => formater.formatCurrency(Number(record.payout || 0)),
    sort: record => Number(record.payout || 0),
  },
};

class Conversions extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    conversions: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    offer: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const { conversions, isFetching } = this.props;
    // const data = conversions.sort((aVal, bVal) => ((new Date(aVal.date)).getTime() > (new Date(bVal.date)).getTime() ? 1 : -1));
    return (
      <div>
        <h4 className="il-color-deep-blue font-weight-bold">{this.props.offer && this.props.offer && this.props.offer.offer_name} Conversions</h4>
        { isFetching
          ?
            <div
              style={{ textAlign: 'center', width: '100%' }}
            >
              <div
                className="spinner-border il-text-gray mt-3"
                role="status"
              />
            </div>
          : <MatTableForRedux
              dataArray={conversions}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              rowsPerPage={5}
              defaultOrder={{
                order: 'desc',
                orderBy: 'date',
              }}
              hideFilterDropdown
              hideToolbar
          />}
      </div>
    );
  }
}
export default connect()(Conversions);
