import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

function isLinkActive(route) {
  let routeCleaned = route;
  const indexOfQuestionMark = routeCleaned.indexOf('?');
  if (indexOfQuestionMark !== -1) {
    routeCleaned = routeCleaned.slice(0, indexOfQuestionMark);
  }
  return window.location.href.includes(routeCleaned);
}

function getCollapse(children) {
  let result = false;
  if (isIterable(children)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < children.length; i++) {
      if (children[i] && children[i].props && children[i].props.route) {
        if (isLinkActive(children[i].props.route)) result = true;
        if (result) break;
      } else if (children[i] && children[i].type && children[i].type.name && children[i].type.name === 'SidebarCategory') {
        if (children[i] && children[i].props && children[i].props.children) {
          result = getCollapse(children[i].props.children);
          if (result) break;
        }
      }
    }
  } else if (children && children.props && children.props.route) {
    if (isLinkActive(children.props.route)) result = true;
  } else if (children && children.type && children.type.name && children.type.name === 'SidebarCategory') {
    if (children && children.props && children.props.children) {
      result = getCollapse(children.props.children);
    }
  }
  return result;
}

export default class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  };

  static defaultProps = {
    icon: '',
    isNew: false,
  };

  constructor(props) {
    super(props);
    const { children } = props;
    this.state = {
      collapse: getCollapse(children),
    };
  }

  toggle = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  render() {
    const {
      title, icon, isNew, children,
    } = this.props;
    const { collapse } = this.state;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
      'sidebar__link sidebar__category': true,
    });

    return (
      <div className="sidebar__category-container">
        <button className={categoryClass} type="button" onClick={this.toggle}>
          {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
          <p className="sidebar__link-title">{title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>
              {children}
            </div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
