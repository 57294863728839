import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { axios } from 'ApiClient';
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SettingsIcon from 'mdi-react/SettingsIcon';
import { Link } from 'react-router-dom';
import Panel from '../../../../shared/components/Panel';
import TransfersTable from './TransfersTable';
import WVValidator from '../../../../shared/helpers/WVValidator';
import PayoutModal from './PayoutModal';

class BillComDashboard extends PureComponent {
  /*
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        balance: false,
        payout: false,
      },
    };
  }
  */

  render() {
    const { influencer } = this.props;

    if (WVValidator.isEmpty(influencer.payment.billCom.accountId) || 
      (!WVValidator.isEmpty(influencer.payment.stripe.accountId) && influencer.payment.stripe.isDefault)) {
      return null;
    }

    return (
      <>
        <Col md={12} className=" mt-3 ml-auto payment-title">
          <div>
            <img className="payment-title-image" alt="Payments" src="/images/creator/paymentSymbol.png" />
          </div>
          <h3 style={{ float: 'left' }}>Payments</h3>
          <UncontrolledDropdown style={{ textAlign: 'end' }}>
            <DropdownToggle className="icon creator-settings-icon" outline>
              <p><SettingsIcon className="mr-0" style={{ width: '26px', height: '24px' }} /></p>
            </DropdownToggle>
            <DropdownMenu right className="dropdown__menu">
              <DropdownItem>
                <Link to="/settings">
                  <p>
                    <span className="topbar__link-icon lnr lnr-cog" />
                    Payment Settings
                  </p>
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Panel showButtons={false} md={12} panelClass="panel-no-vertical-padding">
          <Row>
            <Col xs="auto" className="my-1">
              <div>
                <img className="il-payments-logo" alt="Creator avatar" src={influencer && influencer.logo ? influencer.logo : '/images/user/avatarLogo1.png'} />
              </div>
            </Col>
            <Col xs="auto" md={3} className="pl-1 my-2">
              <h3 className="il-color-deep-blue font-weight-bold">{`${influencer.first_name} ${influencer.last_name}`}</h3>
              {
                (influencer.accountType !== undefined && influencer.accountType === 1) &&
                <h4>{`${influencer.companyName}`}</h4>
              }
            </Col>
          </Row>
        </Panel>
        <PayoutModal />
        <Panel showButtons={false} md={12} panelClass="panel-no-vertical-padding">
          <TransfersTable isStripe={false} />
        </Panel>
      </>
    );
  }
}

BillComDashboard.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BillComDashboard;
