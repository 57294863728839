import React, { PureComponent } from 'react';
import { Spinner, Card, CardBody, Col, Row, Button } from 'reactstrap';
import { axios } from 'ApiClient';
import { uid } from 'react-uid';
import * as PropTypes from 'prop-types';

const styles = {
  iconImg: {
    width: '27px',
    marginTop: '-7px',
  },
  opportunityCard: {
    height: 'auto',
    paddingBottom: 0,
    margin: '30px 0',
  },
  logoUrl: {
    maxHeight: '120px',
    maxWidth: '200px',
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    float: 'left',
  },
  title: {
    color: '#015097',
    fontWeight: 'bold',
  },
  cardBody: {
    height: 'auto',
    padding: '15px 30px',
  },
  firstRow: {
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: '15px',
  },
  description: {
    margin: '20px 0 0 0',
    padding: '10px 40px 0 40px',
    borderLeft: '1px solid #e0e0e0',
  },
  okSign: {
    width: '24px',
    marginRight: '10px',
    float: 'left',
  },
  colHighlights: {
    margin: '20px 0 0 0',
    padding: '10px 10px 0 10px',
  },
  highlightItem: {
    margin: '10px 0',
  },
  colH3: {
    padding: '0 40px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    float: 'right',
  },
  requestSent: {
    color: '#70bbfd',
    fontWeight: 'bold',
    fontSize: '15px',
    float: 'right',
    paddingBottom: '7px',
    marginBottom: '20px',
  },
  clock: {
    width: '18px',
    margin: '-3px 5px 0 0',
  },
};

class BrandOpportunitiesPage extends PureComponent {
  static propTypes = {
    addNotice: PropTypes.func.isRequired,
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: {
        opportunities: false,
      },
      opportunities: false,
      introText: false,
      version: 0,
    };

    this.requestMoreInfo = this.requestMoreInfo.bind(this);
  }

  componentDidMount() {
    this.fetchBrandOpportunitiesInfo();
  }


  fetchBrandOpportunitiesInfo() {
    this.setState({
      loading: {
        opportunities: true,
      },
    });

    axios.get('/api/creator/opportunities/get-opportunities').then((response) => {
      const { opportunities, introText } = response.data;
      this.setState({
        opportunities: opportunities || false,
        introText: introText || false,
        loading: {
          opportunities: false,
        },
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: {
          opportunities: false,
        },
      });
    });
  }

  requestMoreInfo(opportunityIndex) {
    const { opportunities } = this.state;
    opportunities[opportunityIndex].requested = 'loading';
    this.setState({
      opportunities,
    }, () => { this.forceUpdate(); });
    axios.post('/api/creator/opportunities/more-info-requested', { id: opportunities[opportunityIndex]._id }).then((response) => {
      const { success, error } = response.data;
      if (success) {
        opportunities[opportunityIndex].requested = true;
        this.setState({
          opportunities,
        }, () => { this.forceUpdate(); });
      } else {
        opportunities[opportunityIndex].requested = false;
        this.setState({
          opportunities,
        }, () => { this.forceUpdate(); });
        this.props.addNotice({
          message: error,
          type: 'error',
        });
      }
    }).catch((error) => {
      console.log(error);
      opportunities[opportunityIndex].requested = false;
      this.setState({
        opportunities,
      }, () => { this.forceUpdate(); });
    });
  }

  render() {
    const {
      loading,
      opportunities,
      introText,
      version,
    } = this.state;
    const { influencer } = this.props;
    if (opportunities) {
      opportunities.map((opportunity, i) => {
        if (typeof opportunities[i].requested === 'undefined' || !opportunities[i].requested) {
          opportunities[i].requested = false;
          if (typeof influencer.opportunities !== 'undefined') {
            influencer.opportunities.map((influencerOpportunity) => {
              if (influencerOpportunity.brandOpportunity === opportunity._id) {
                opportunities[i].requested = true;
              }
              return true;
            });
          }
        }
        return true;
      });
    }

    return (
      <Card>
        <CardBody style={{ backgroundColor: 'transparent' }} key={`opportunities-${version}`}>
          {loading.opportunities &&
          (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )
          }
          {!loading.opportunities && opportunities &&
          (
            <>
              <Row>
                <Col md={12} xs={12}>
                  <h2><img style={styles.iconImg} src="/images/opportunities/opportunities-icon.png" alt="" /> Additional Brand Opportunities</h2>
                  <br />
                  <p>
                    {introText}
                  </p>
                  <br />
                  {opportunities.map((opportunity, i) => (
                    <Card key={opportunity._id} style={styles.opportunityCard}>
                      <CardBody style={styles.cardBody}>
                        <Row style={styles.firstRow}>
                          <Col md={3} xs={12}>
                            {opportunity.logoUrl && (<img src={opportunity.logoUrl} style={styles.logoUrl} alt="" />)}
                            {!opportunity.logoUrl && opportunity.brand.logoUrl && (<img src={opportunity.brand.logoUrl} style={styles.logoUrl} alt="" />)}
                          </Col>
                          <Col md={9} xs={12} style={styles.colH3}>
                            <h3 style={styles.title}>{opportunity.title}</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3} xs={12} style={styles.colHighlights}>
                            {opportunity.highlights && opportunity.highlights.map(highlight => (
                              <div key={uid(opportunity._id + highlight.itemText)} style={styles.highlightItem}>
                                <img src="/images/opportunities/ok-sign.png" alt="" style={styles.okSign} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <span dangerouslySetInnerHTML={{ __html: highlight.itemText }} />
                              </div>
                              ))}
                          </Col>
                          <Col md={9} xs={12} style={styles.description}>
                            {/* eslint-disable-next-line react/no-danger */}
                            <span dangerouslySetInnerHTML={{ __html: opportunity.description }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12}>
                            {!opportunity.requested && (
                              <Button
                                style={styles.button}
                                color="primary"
                                size="sm"
                                onClick={() => { this.requestMoreInfo(i); }}
                              >
                                Request More Info
                              </Button>
                            )}
                            {opportunity.requested === true && (
                              <div style={styles.requestSent}>
                                <img src="/images/opportunities/clock.png" alt="" style={styles.clock} />
                                Thank you for requesting more information. You will be hearing from us shortly
                              </div>
                            )}
                            {opportunity.requested === 'loading' && (
                              <Spinner color="primary" size="sm" style={{ float: 'right' }} />
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default BrandOpportunitiesPage;
