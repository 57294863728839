import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, CardTitle, CardText, CardDeck,
  CardImg, CardBody, CardFooter, Button,
  FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StepStatus from './StepStatus';
import SetupStripeAlert from './stripe/SetupStripeAlert';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import CheckBox from '../../../../shared/components/form/CheckBox';

const OnboardingDashboard = ({
  influencer, agreements, brands, loadingAgreements, signAgreementDoc, reviewResources, allAgreements,
  signBillCom,
}) => {
  const [showStripe, setShowStripe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [useBillCom, setUseBillCom] = useState(false);
  const [billComLoading, setBillComLoading] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(agreements && agreements[0] && agreements[0]._id);
  // console.log(selectedAgreement);
  useEffect(() => {
    setSelectedAgreement(agreements && agreements[0] && agreements[0]._id);
  }, [agreements]);
  const getDeals = () => {
    let deals = [];
    if (influencer.deals) {
      agreements.map((a) => {
        let deal = a && a.deals[0];
        // console.log(deal);
        deal = deal && influencer.deals.find(d => d.id === deal._id);
        if (deal) {
          deals.push(deal);
        }
        return true;
      });
      if (deals.length === 0) {
        allAgreements.map((a) => {
          let deal = a && a.deals[0];
          // console.log(deal);
          deal = deal && influencer.deals.find(d => d.id === deal._id);
          if (deal) {
            deals.push(deal);
          }
          return true;
        });
      }

      if (!agreements || (agreements.length === 0 && allAgreements.length === 0)) {
        ({ deals } = influencer);
      }
    }
    return deals;
  };
  const getBrandName = () => {
    const deals = getDeals();
    if (deals.length <= 1) {
      return (deals && deals[0] && deals[0].advertiser && deals[0].advertiser.companyName) || 'brand';
    }
    // console.log(deals);
    const ds = [...new Set(deals.map(d => (d.advertiser ? d.advertiser.companyName : '')))];
    const allDs = ds.splice(-1, 1);
    let returnString = ds.join(', ');
    returnString = `${returnString}${returnString && ' and '}${allDs[0]}`;
    return returnString;
  };
  console.log(useBillCom || (influencer && influencer.payment.enablePayments === false));
  return (
    <div className="onboarding-container">
      <h2 className="dashboard-title"><img src="/images/creator/icon.png" alt="some" />{' '}Let&apos;s Get Started</h2>
      <CardDeck>
        <Card className="card--not-full-height onboarding step1">
          <div className="card-top-label">Step 1</div>
          <CardBody>
            <CardTitle tag="h2" className="il-color-deep-blue">Setup Payment Account <StepStatus onboarding={influencer.onboarding} step={3} /></CardTitle>
            <CardImg top width="100%" src="/images/creator/stripe.png" alt="Card image cap" />
            <CardText>We use <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a> to make sure you get paid on time and to ensure your personal information and bank details are kept
              {' '}safe and secure. You will be redirected to Stripe’s simple online setup process to complete your payment account setup. This process typically takes no longer than 5 minutes.
            </CardText>
          </CardBody>
          <CardFooter className="text-center">
            {influencer.onboarding.step3Status !== 'complete' ? (
              <>
                <Button
                  className="radius-button"
                  color="primary"
                  onClick={() => {
                    setShowStripe(true);
                    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
                  }}
                  disabled={useBillCom || (influencer && influencer.payment.enablePayments === false)}
                >
                  Setup Payment Account Now
                </Button>
                <div>
                  <FormGroup check inline className="text-left">
                    <CheckBox
                      label="Opt-out of using Stripe?"
                      input={{
                        onChange: e => e.target && setUseBillCom(e.target.checked),
                        value: useBillCom,
                        name: 'acceptTerms',
                      }}
                    />
                    <Label check className="">
                      {useBillCom && influencer.onboarding.step3Status !== 'complete' ? (
                        <ButtonWithSpinner
                          color="primary"
                          className="radius-button mb-2 ml-2"
                          onClick={() => {
                            setBillComLoading(true);
                            signBillCom();
                          }}
                          loading={billComLoading}
                          disabled={billComLoading}
                        >
                          Confirm
                        </ButtonWithSpinner>) : null}
                    </Label>
                  </FormGroup>
                  <p>
                    Click <button style={{ backgroundColor: 'rgba(255, 255, 255, 0)', border: 'none' }} className="p-0 m-0 font-weight-bold" onClick={() => setShowModal(true)}>here</button>{' '}
                    to determine if you are eligible or not to use Stripe for InfluenceLogic payments.
                  </p>
                </div>
              </>
            ) : null}
          </CardFooter>
        </Card>
        <Card className="card--not-full-height onboarding">
          <div className="card-top-label">Step 2</div>
          <CardBody>
            <CardTitle tag="h2" className="il-color-deep-blue">Sign Your Agreement <StepStatus onboarding={influencer.onboarding} step={1} /></CardTitle>
            <CardImg top width="100%" src="/images/creator/sign.png" alt="Card image cap" />
            <CardText>
              {(agreements && allAgreements && agreements.length === 0 && allAgreements.length === 0)
                ? `Your Creator Services Agreement for ${getBrandName()} ${[...new Set(getDeals().map(d => (d.advertiser ? d.advertiser.companyName : '')))].length > 1 ? 'are' : 'is'}
                  being prepared and will be available shortly for you to sign using our online document signing system.`
                : (
                  <span>Your <strong>Creator Services Agreement</strong> for {getBrandName()}
                    {' '}{[...new Set(getDeals().map(d => (d.advertiser ? d.advertiser.companyName : '')))].length > 1 ? 'are' : 'is'}
                    {' '}ready to e-sign securely using DocuSign. After you complete the signing process, you will find a copy of the agreement on the
                    {' '}<strong>Documents</strong> page.
                  </span>)}
            </CardText>
          </CardBody>
          <CardFooter className="text-center">
            {(!agreements || agreements.length === 0) && influencer.onboarding.step1Status === 'complete' ? (
              <div>
                <h5 className="font-weight-bold mb-2">Signed Agreement on: {influencer.onboarding.step1DateCompleted ? moment(influencer.onboarding.step1DateCompleted).format('M/D/YY') : '-'}</h5>
                <Link to="/documents">
                  <Button
                    className="radius-button"
                    color="primary"
                  >
                    View My Documents
                  </Button>
                </Link>
              </div>
            ) : null}
            { influencer.onboarding.step3Status === 'complete' && (agreements.length <= 1 || loadingAgreements) ? (
              <Button
                className="radius-button"
                color="primary"
                disabled={!agreements || agreements.length === 0 || loadingAgreements || influencer.onboarding.step1Status === 'complete'}
                onClick={() => signAgreementDoc(agreements && agreements[0]._id)}
              >
                Sign Agreement Now
              </Button>) : null}
            {agreements && (agreements.length > 1 && !loadingAgreements) ? (
              <Row form>
                <Col md={7}>
                  <Input type="select" name="select" id="exampleSelect" value={selectedAgreement} onChange={e => setSelectedAgreement(e.target.value)}>
                    {agreements.map(a => (<option value={a._id} key={a._id}>{(a.type === 'keen') ? 'Keen Service Provider Agreement' : a.deals[0]?.dealname}</option>))}
                  </Input>
                </Col>
                <Col md={5}>
                  <Button
                    className="radius-button"
                    color="primary"
                    onClick={() => signAgreementDoc(selectedAgreement)}
                  >
                    Sign Agreement Now
                  </Button>
                </Col>
              </Row>
            ) : null}
          </CardFooter>
        </Card>
        <Card className="card--not-full-height onboarding">
          <div className="card-top-label">Step 3</div>
          <CardBody>
            <CardTitle tag="h2" className="il-color-deep-blue">Review Resources <StepStatus onboarding={influencer.onboarding} step={2} /></CardTitle>
            <CardImg top width="100%" src="/images/creator/review.png" alt="Card image cap" />
            <CardText>
              {(agreements && allAgreements && agreements.length === 0 && allAgreements.length === 0)
              ? 'Once you have signed your Creator Services Agreements you will be able to review our Brand Resources page(s). ' +
                'There you will find information to help you get up and running including our brand guidelines, educational materials and creative assets.'
              : `Review the ${getBrandName()} Resources page(s). There you will find information to help you get up and running including our brand guidelines, educational materials and creative assets.`}
            </CardText>
          </CardBody>
          <CardFooter className="text-center">

            { influencer.onboarding.step1Status === 'complete' && influencer.onboarding.step3Status === 'complete' ? (
              <div>
                <h5 className="font-weight-bold mb-2">Reviewed on: {influencer.onboarding.step2DateCompleted ? moment(influencer.onboarding.step2DateCompleted).format('M/D/YY') : '-'}</h5>
              </div>
            ) : null}
            <Button
              className="radius-button"
              color="primary"
              onClick={reviewResources}
              disabled={(influencer && influencer.onboarding.step3Status === 'pending') || !brands || brands.length === 0}
            >
              Review Resources Now
            </Button>
          </CardFooter>
        </Card>
      </CardDeck>
      {showStripe && !useBillCom ? (<SetupStripeAlert influencerOrAgent={influencer} type="creator"/>) : null}
      <Modal isOpen={showModal} toggle={() => setShowModal(false)} className="delete-modal ltr-support" size="sm" style={{ maxWidth: '400px' }}>
        <ModalHeader className="il-color-deep-blue"><h4><FontAwesomeIcon icon={['fa', 'info-circle']} /> Info</h4></ModalHeader>
        <ModalBody>
          Currently, we are only able to send payments to our Creators via Stripe if you are based in the US, UK, or Canada and have a bank account 
          in one of these countries. If you are a non-US, UK, or Canada based Creator, we offer an alternative payment solution through 
          <a
            href='https://www.bill.com'
            className="font-weight-bold"
          >
            {' '}Bill.com{' '}
          </a>
          <br />
          Please contact your IPM representative or our 
          <a
            href='mailto:customersuccess@influencelogic.com'
            className="font-weight-bold"
          >
            {' '}Customer Success team{' '}
          </a>
          to learn more about this solution and get connected.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)} className="radius-button">Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
OnboardingDashboard.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  agreements: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  allAgreements: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loadingAgreements: PropTypes.bool.isRequired,
  signAgreementDoc: PropTypes.func.isRequired,
  reviewResources: PropTypes.func.isRequired,
  signBillCom: PropTypes.func.isRequired,
};
OnboardingDashboard.defaultProps = {
  agreements: [],
  allAgreements: [],
  brands: [],
};
export default OnboardingDashboard;
