/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import moment from 'moment';
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice, initializeSession } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const agentReducer = createSlice({
  name: 'agents',
  initialState: {
    loading: {
      total: false,
      creatorContent: false,
      currentCreator: false,
      totalWithoutStatistics: false,
      agreements: false,
    },
    dateFrom: moment().startOf('month').set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).unix(),
    dateTo: moment().unix(),
    lastFetchedAllData: new Date(0),
    lastFetchedAllDataWithoutStatistics: new Date(0),
    clicksCount: 0,
    conversionsCount: 0,
    payout: 0,
    creators: [],
    selectedButton: 'month_to_date',
    currentCreator: {
      id: null,
      firstName: null,
      lastName: null,
      dateFrom: moment().startOf('month').set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).unix(),
      dateTo: moment().unix(),
      clicksCount: 0,
      conversionsCount: 0,
      payout: 0,
      deals: [],
      loading: {
        total: false,
      },
      lastFetched: new Date(0),
      selectedButton: 'month_to_date',
      haveUpfront: false,
      offers: [],
      activeDeals: [],
    },
    selectedOffer: null,
    creatorContent: [],
    agreementsToSign: [],
  },
  reducers: {
    setDates(state, action) {
      const { dateFrom, dateTo } = action.payload;
      state.dateFrom = dateFrom;
      state.dateTo = dateTo;
    },
    setLoading(state, action) {
      state.loading.total = action.payload;
      state.currentCreator.loading.total = action.payload;
    },
    setLoadingByName(state, action) {
      const { isLoading, section } = action.payload;
      state.loading = { ...state.loading, [section]: isLoading };
    },
    setResponse(state, action) {
      const {
        clicksCount, conversionsCount, payout, creators, haveUpfront,
      } = action.payload;
      creators.forEach((creator, index) => {
        creators[index].lastFetched = new Date();
      });
      state.clicksCount = parseInt(clicksCount, 10);
      state.conversionsCount = parseInt(conversionsCount, 10);
      state.payout = parseFloat(payout);
      state.haveUpfront = !!haveUpfront;
      state.creators = creators;
      state.lastFetchedAllData = new Date();
      // if (state.offerId) {
      //   const offer = state.offers.find(o => o._id === state.offerId);
      //   state.selectedOffer = offer;
      // }
    },
    setResponseWithoutStatistics(state, action) {
      const {
        creators,
      } = action.payload;
      creators.forEach((creator, index) => {
        creators[index].lastFetched = new Date();
      });
      state.creators = creators;
      state.lastFetchedAllDataWithoutStatistics = new Date();
    },
    reSetResponse(state) {
      state.clicksCount = 0;
      state.conversionsCount = 0;
      state.payout = 0;
      state.haveUpfront = false;
      state.creators = [];
    },
    reSetResponseWithoutStatistics(state) {
      state.creators = [];
    },
    setSelectedButton(state, action) {
      const selectedButton = action.payload;
      state.selectedButton = selectedButton;
    },
    setCurrentCreatorDates(state, action) {
      console.log(action.payload);
      const { dateFrom, dateTo } = action.payload;
      state.currentCreator.dateFrom = dateFrom;
      state.currentCreator.dateTo = dateTo;
      console.log(state);
    },
    setCurrentCreatorLoading(state, action) {
      state.currentCreator.loading.total = action.payload;
    },
    setCurrentCreatorResponse(state, action) {
      const {
        clicksCount, conversionsCount, payout, haveUpfront, creators,
      } = action.payload;
      state.currentCreator = {
        ...state.currentCreator,
        clicksCount: parseInt(clicksCount, 10),
        conversionsCount: parseInt(conversionsCount, 10),
        payout: parseFloat(payout),
        haveUpfront: !!haveUpfront,
        offers: creators[0].offers,
        activeDeals: creators[0].activeDeals,
        lastFetched: new Date(),
        id: creators[0]._id,
        firstName: creators[0].first_name,
        lastName: creators[0].last_name,
      };
      if (state.selectedOffer !== null && state.selectedOffer !== undefined && typeof state.selectedOffer !== 'undefined') {
        const offer = state.currentCreator.offers.find(o => o._id === state.selectedOffer._id);
        state.selectedOffer = offer;
      }
    },
    reSetCurrentCreatorResponse(state) {
      state.currentCreator = {
        ...state.currentCreator,
        clicksCount: 0,
        conversionsCount: 0,
        payout: 0,
        haveUpfront: false,
        offers: [],
        id: null,
        firstName: null,
        lastName: null,
      };
    },
    setCurrentCreatorSelectedButton(state, action) {
      state.currentCreator.selectedButton = action.payload;
    },
    selectOffer(state, action) {
      const offerId = action.payload;
      const offer = state.currentCreator.offers.find(o => o._id === offerId);
      if (offer) {
        state.selectedOffer = offer;
      }
      return state;
    },
    getCreatorStart(state) {
      state.loading = {
        ...state.loading,
        currentCreator: true,
      };
    },
    getCreatorSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        currentCreator: false,
      };
      state.error = null;
      state.currentCreator = data;
    },
    getCreatorError(state) {
      state.loading = {
        ...state.loading,
        currentCreator: false,
      };
    },
    getCreatorContentStart(state) {
      state.loading = {
        ...state.loading,
        creatorContent: true,
      };
    },
    getCreatorContentSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        creatorContent: false,
      };
      state.creatorContent = data;
    },
    getCreatorContentError(state) {
      state.loading = {
        ...state.loading,
        creatorContent: false,
      };
    },
    setAgreement(state, action) {
      state.agreementsToSign = action.payload;
    },
  },
});
export const {
  setDates,
  setLoading,
  setResponse,
  setResponseWithoutStatistics,
  reSetResponse,
  reSetResponseWithoutStatistics,
  selectOffer,
  setLoadingByName,
  setSelectedButton,
  setCurrentCreatorDates,
  setCurrentCreatorLoading,
  setCurrentCreatorResponse,
  setCurrentCreatorSelectedButton,
  reSetCurrentCreatorResponse,
  // get creator by Id
  getCreatorStart,
  getCreatorSuccess,
  getCreatorError,
  // Creator content
  getCreatorContentStart,
  getCreatorContentSuccess,
  getCreatorContentError,
  setAgreement,
} = agentReducer.actions;

const getApiData = async ({ dateFrom, dateTo, creatorId }) => {
  const url = `/api/agent/dashboard/get-stats?dateFrom=${moment.unix(dateFrom).format('YYYY-MM-DD')}&dateTo=${moment.unix(dateTo).format('YYYY-MM-DD')}&creatorId=${creatorId}`;
  const api = new ApiClient();
  return api.client.get(url);
};

const getApiDataWithoutStatistics = async ({ creatorId }) => {
  const url = `/api/agent/dashboard/get-creators-without-statistics?creatorId=${creatorId}`;
  const api = new ApiClient();
  return api.client.get(url);
};

const getAgentApi = async (session) => {
  const api = new ApiClient();
  return api.client.post('/api/agent/get-agent', { authCode: session?.authCode });
};

const getCreatorWithoutStatsApi = async (creatorId) => {
  const api = new ApiClient();
  return api.client.get(`/api/agent/get-agent-creator?creatorId=${creatorId}`);
};

const getCreatorContentApi = async (creatorId) => {
  const api = new ApiClient();
  return api.client.get(`/api/agent/creator/get-content?creatorId=${creatorId}`);
};

const getAgreements = async () => {
  const api = new ApiClient();
  return api.client.get('/api/agent/agreements-to-sign');
};

export const fetchData = ({ dateFrom, dateTo, creatorId }) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await getApiData({ dateFrom, dateTo, creatorId });
    if (response.data.success === true) {
      dispatch(setResponse(response.data.data));
    } else {
      dispatch(reSetResponse());
    }
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const fetchDataWithoutStatistics = () => async (dispatch) => {
  dispatch(setLoadingByName({ section: 'totalWithoutStatistics', isLoading: true }));
  try {
    const response = await getApiDataWithoutStatistics({});
    if (response.data.success === true) {
      dispatch(setResponseWithoutStatistics(response.data.data));
    } else {
      dispatch(reSetResponseWithoutStatistics());
    }
    dispatch(setLoadingByName({ section: 'totalWithoutStatistics', isLoading: false }));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingByName({ section: 'totalWithoutStatistics', isLoading: false }));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const fetchCurrentCreatorData = ({ dateFrom, dateTo, creatorId }) => async (dispatch) => {
  dispatch(setCurrentCreatorLoading(true));
  // eslint-disable-next-line max-len
  try {
    const response = await getApiData({ dateFrom, dateTo, creatorId });
    if (response.data.success === true && typeof response.data.data !== 'undefined' && typeof response.data.data.creators !== 'undefined' && response.data.data.creators.length > 0) {
      dispatch(setCurrentCreatorResponse(response.data.data));
    } else {
      dispatch(reSetCurrentCreatorResponse());
    }
    dispatch(setCurrentCreatorLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setCurrentCreatorLoading(false));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const checkAgent = (fnc) => async (dispatch, getState) => {
  try {
    const { session } = getState();
    const response = await getAgentApi(session);
    // console.log(response);
    if (!response.data.success === true || !response.data.user) {
      throw new Error('Invalid Agent');
    }
    const {
      user, adminUser, contact,
    } = response.data;
    // user.deals = deals;
    user.adminUser = adminUser;
    user.contact = contact;
    // user.brandResources = brandResources;
    // if (docusign && docusign.enabled) {
    //   const navDocuments = document.getElementById('navDocuments');
    //   if (navDocuments) {
    //     navDocuments.style.display = 'block';
    //   }
    // }
    if (!_.isEqual(user, session)) {
      dispatch(initializeSession(user));
    }
    if (fnc) fnc();
  } catch (e) {
    console.log('ERROR:', e.message);
    dispatch(initializeSession({}));
    document.location.href = '/login';
  }
};

export const getCreatorWithoutStats = creatorId => async (dispatch) => {
  try {
    dispatch(getCreatorStart());
    const response = await getCreatorWithoutStatsApi(creatorId);
    if (response.data) {
      dispatch(getCreatorSuccess({ data: response.data.record }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getAgentCreatorContent = creatorId => async (dispatch) => {
  try {
    dispatch(getCreatorContentStart());
    const response = await getCreatorContentApi(creatorId);
    if (response.data) {
      dispatch(getCreatorContentSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorContentError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getAgreementsFetch = () => async (dispatch) => {
  dispatch(setLoadingByName({ section: 'agreements', isLoading: true }));
  // eslint-disable-next-line max-len
  try {
    const response = await getAgreements();
    // const allAgreementsResp = await getAllAgreements();
    if (response.data.success === true) {
      dispatch(setAgreement(response.data.agreements));
    } else {
      dispatch(setAgreement([]));
    }
    // if (allAgreementsResp.data.success === true) {
    //   dispatch(setAllAgreement(allAgreementsResp.data.agreements));
    // } else {
    //   dispatch(setAllAgreement([]));
    // }
    dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingByName({ section: 'agreements', isLoading: false }));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export default agentReducer.reducer;
