import React, { PureComponent } from 'react';
// import { axios } from 'ApiClient';
import { ButtonToolbar, Card, CardBody, Container, Col, Row, Input, FormGroup, FormFeedback, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import Breadcrumb from '../../../shared/components/BreadCrumbs';
import withNotice from '../../App/store/with-notice';
import WVValidator from '../../../shared/helpers/WVValidator';
import ButtonWithSpinner from '../../../shared/components/ButtonWithSpinner';


class InternationalAccount extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    rtl: RTLProps.isRequired,
    // addNotice: PropTypes.func.isRequired,
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
    // accountData: PropTypes.objectOf(PropTypes.any).isRequired,
    // history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    const { influencer } = props;

    this.state = {
      firstName: influencer.first_name,
      lastName: influencer.last_name,
      email: '',
      accountType: 'personal',
      bankCountryCode: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      // accountHolderName: '',
      // sortCode: '',
      // accountNumber: '',
      errors: {},
      loading: {
        submit: false,
      },
    };
  }

  componentDidMount() {
    document.title = 'IL Creator Portal: Create Account';
  }

  onInputChanged = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmit = () => {
    if (!this.validateErrors()) { return; }

    this.setState({
      loading: {
        submit: true,
      },
    });

    /*
    axios({
      method: 'post',
      url: '/api/creator/stripe/create-payout',
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success) {
        this.setState({
          loading: {
            payout: false,
          },
        });

        this.props.addNotice({
          message: 'Successfully generated a payout of the available balance.',
          type: 'success',
        });
        this.fetchStripeBalance();
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
    });
    */
  }

  validateErrors() {
    const {
      firstName,
      lastName,
      email,
      bankCountryCode,
      address1,
      city,
      state,
      zip,
    } = this.state;

    const errors = {};
    if (WVValidator.isEmpty(firstName)) {
      errors.firstName = 'Please provide your first name!';
    }
    if (WVValidator.isEmpty(lastName)) {
      errors.firstName = 'Please provide your last name!';
    }
    if (!WVValidator.isEmail(email)) {
      errors.firstName = 'Please check your email format!';
    }
    if (WVValidator.isEmpty(bankCountryCode)) {
      errors.firstName = 'Please provide your country!';
    }
    if (WVValidator.isEmpty(address1)) {
      errors.firstName = 'Please provide your address!';
    }
    if (WVValidator.isEmpty(city)) {
      errors.firstName = 'Please provide your city!';
    }
    if (WVValidator.isEmpty(state)) {
      errors.firstName = 'Please provide your state!';
    }
    if (WVValidator.isEmpty(zip)) {
      errors.firstName = 'Please provide your zip code!';
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  render() {
    const {
      firstName,
      lastName,
      accountType,
      email,
      country,
      address1,
      address2,
      city,
      state,
      zip,
      loading,
      errors,
    } = this.state;

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Payments', path: '/payments' },
            { title: 'Create Account', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Create Account</h5>
                  <h5 className="subhead">Complete your account in order to get payments from InfluenceLogic</h5>
                </div>
                {/* ACCOUNT INFORMATION */}
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Information</h4>
                  <hr className="mt-3 mb-4" />
                  <Row>
                    <Col xs="12" lg="6">
                      <FormGroup>
                        <Label>First Name</Label>
                        <Input type="text" name="firstName" value={firstName} onChange={this.onInputChanged} invalid={errors.firstName} />
                        <FormFeedback>errors.firstName</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12" lg="6">
                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input type="text" name="lastName" value={lastName} onChange={this.onInputChanged} invalid={errors.lastName} />
                        <FormFeedback>errors.lastName</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input type="email" name="email" value={email} onChange={this.onInputChanged} invalid={errors.email} />
                    <FormFeedback>errors.email</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Account Type</Label>
                    <Input type="select" name="accountType" value={accountType} onChange={this.onInputChanged} invalid={errors.email} >
                      <option value="personal">Personal</option>
                      <option value="company">Company</option>
                    </Input>
                    <FormFeedback>errors.accountType</FormFeedback>
                  </FormGroup>
                </section>
                {/* ADDRESS */}
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Address</h4>
                  <hr className="mt-3 mb-4" />
                  <FormGroup>
                    <Label>Country</Label>
                    <Input type="text" name="country" value={country} onChange={this.onInputChanged} invalid={errors.country} />
                    <FormFeedback>errors.country</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Address 1</Label>
                    <Input type="text" name="address1" value={address1} onChange={this.onInputChanged} invalid={errors.address1} />
                    <FormFeedback>errors.address1</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Address 2</Label>
                    <Input type="text" name="address2" value={address2} onChange={this.onInputChanged} invalid={errors.address2} />
                    <FormFeedback>errors.address2</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>City</Label>
                    <Input type="text" name="city" value={city} onChange={this.onInputChanged} invalid={errors.city} />
                    <FormFeedback>errors.city</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>State/Province</Label>
                    <Input type="text" name="state" value={state} onChange={this.onInputChanged} invalid={errors.state} />
                    <FormFeedback>errors.state</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Postal Code</Label>
                    <Input type="text" name="zip" value={zip} onChange={this.onInputChanged} invalid={errors.zip} />
                    <FormFeedback>errors.zip</FormFeedback>
                  </FormGroup>
                </section>
                {/* BANK INFO */}
                <ButtonToolbar className="form__button-toolbar mt-4">
                  <ButtonWithSpinner
                    color="primary"
                    onClick={this.onSubmit}
                    loading={loading.submit}
                  >
                    Submit
                  </ButtonWithSpinner>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withNotice(connect(state => ({
  rtl: state.rtl,
  influencer: state.session,
}))(InternationalAccount));
