import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import Alert from '../../../../shared/easydev2/shared/components/Alert';

const InfoRequiredAlert = ({ portal, creator }) => {
  const [capabilitiesStatus, setCapabilitiesStatus] = useState('active');
  const [response, setResponse] = useState(null);
  // requirements
  useEffect(() => {
    fetchData();
  }, []);

  /* const transformErrorToMessage = (error) => {
    const transformToHumanView = (str) => str
      .split('.')
      .map(word => word.replace(/_/g, ' ')) // Replace underscores with spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' ');

    const fields = {
      'external_account': 'External Bank Account'
    }

    return fields[error] ?? transformToHumanView(error);
  } */

  const fetchData = () => {
    axios.get(`/api/${portal}/stripe/account`)
      .then((response) => {
        console.log(response.data)
        if (response.data.success && response.data.account) {
          const { account } = response.data;
          let status = 'active';
          const values = Object.values(account.capabilities);
          if (values.includes('pending')) {
            status = 'pending';
          } else if (values.includes('inactive')) {
            status = 'inactive';
          } else if (account.future_requirements?.currently_due?.length > 0 || account.requirements?.currently_due?.length > 0) {
            status = 'restricted_soon';
          }
          setResponse(account);

          setCapabilitiesStatus(status);
        } else {
          setCapabilitiesStatus('error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const disabledReason = response?.requirements.disabled_reason;
  /* const returrnMessage = (response) => {
    const message = [];
    if (response.payouts_enabled !== true) {
      message.push(<p>Your Stripe account is not able to receive payouts and process payments. See below for more details.</p>)
    } else {
      message.push(<p>Your Stripe account is able to receive payouts and process payments, but some information needs to be filled out.</p>)
    }
    if (response.requirements.disabled_reason !== null) {
      const messagesError = [];
      if (response.requirements.disabled_reason === 'requirements.past_due') {
        response.requirements.past_due.forEach(error => {
          messagesError.push(transformErrorToMessage(error))
        })
      } else {
        messagesError.push('Invalid Provided Information')
      }
      message.push(<p>Your account was disabled, and you need to provide the following information: <ul>{messagesError.map(a => (<li>{a}</li>))}</ul></p>)

    }
    if (response.requirements.currently_due.length > 0) {
      const messagesError = [];
      response.requirements.currently_due.forEach(error => {
        messagesError.push(transformErrorToMessage(error))
      })
      message.push(<p>The information that needs to be provided now: <ul>{messagesError.map(a => (<li>{a}</li>))}</ul></p>)
    }
    if (response.requirements.eventually_due.length > 0) {
      const messagesError = [];
      response.requirements.eventually_due.forEach(error => {
        messagesError.push(transformErrorToMessage(error))
      })
      message.push(<p className="py-2">The information that needs to be provided in the near future: <ul>{messagesError.map(a => (<li>{a}</li>))}</ul></p>)
    }
    return message;
  }
  const returrnMessageFuture = (response) => {
    const message = [];
    if (response.future_requirements.past_due.length > 0) {
      const messagesError = [];
      response.future_requirements.past_due.forEach(error => {
        messagesError.push(transformErrorToMessage(error))
      })
      message.push(<p>The information that needs to be provided: <ul>{messagesError.map(a => (<li>{a}</li>))}</ul></p>)
    }
    if (response.future_requirements.currently_due.length > 0) {
      const messagesError = [];
      response.future_requirements.currently_due.forEach(error => {
        messagesError.push(transformErrorToMessage(error))
      })
      message.push(<p>The information that needs to be provided now: <ul>{messagesError.map(a => (<li>{a}</li>))}</ul></p>)
    }
    if (response.future_requirements.eventually_due.length > 0) {
      const messagesError = [];
      response.future_requirements.eventually_due.forEach(error => {
        messagesError.push(transformErrorToMessage(error))
      })
      message.push(<p className="py-2">The information that needs to be provided in the near future: <ul>{messagesError.map(a => (<li>{a}</li>))}</ul></p>)
    }
    return message;
  } */
  const contactString = <p>To ensure there are no delays in processing your payments, please update your Stripe account information by clicking the <a href="/stripe/dashboard" target="_blank" rel="noreferrer" >Update Information</a> button below. Once inside your Stripe account, click the <strong>Add information</strong> button in the <strong>Complete Onboarding</strong> alert message.
    If you need assistance, our support team is here to help. Please contact <strong>Customer Success</strong> at <a rel="noreferrer" href='mailto:customersuccess@influencelogic.com' target='_blank'>customersuccess@influencelogic.com</a>.</p>

  if (disabledReason) {
    return <Alert color="warning" hideCloseButton bordered whiteBg icon>
      <div>
        <h4 className="alert-title mt-2 mb-3">Action Required: Update Your Stripe Account</h4>
        <Col xs="12" lg="auto">
          {contactString}
          <a href="/stripe/dashboard" target="_blank" rel="noreferrer">
            <Button
              outline
              color="primary"
              className="mt-2 mb-0"
              size="md"
            >
              <p>Update Information &nbsp;<SendIcon /> </p>
            </Button>
          </a>
        </Col>
      </div>
    </Alert>
  } /* else if (response?.requirements.eventually_due.length > 0 || response?.requirements.currently_due.length > 0 || response?.requirements.past_due.length > 0) {
    return <Alert color="warning" hideCloseButton bordered whiteBg icon>
      <div>
        <h4 className="alert-title mt-2 mb-3">Action Required: Update Your Stripe Account</h4>
        
        <Col xs="12" lg="auto">          
          {contactString}
          <a href="/stripe/dashboard" target="_blank" rel="noreferrer">
            <Button
              outline
              color="primary"
              className="mt-2 mb-0"
              size="md"
            >
              <p>Update Information &nbsp;<SendIcon /> </p>
            </Button>
          </a>
        </Col>
      </div>
    </Alert>
  } else if (response?.future_requirements.eventually_due.length > 0 || response?.future_requirements.currently_due.length > 0 || response?.future_requirements.past_due.length > 0) {
    return <Alert color="primary" hideCloseButton bordered whiteBg icon>
      <div>
        <h4 className="alert-title mt-2 mb-3">Action Recommended: Update Your Stripe Account</h4>
        
        <Col xs="12" lg="auto">          
          {contactString}
          <a href="/stripe/dashboard" target="_blank" rel="noreferrer">
            <Button
              outline
              color="primary"
              className="mt-2 mb-0"
              size="md"
            >
              <p>Update Information &nbsp;<SendIcon /> </p>
            </Button>
          </a>
        </Col>
      </div>
    </Alert>
  } */

  if (capabilitiesStatus === 'active') { return null; }

  return (
    <Alert color={capabilitiesStatus === 'inactive' ? 'danger' : 'warning'} className="alert--bordered bg-white mb-4" icon>
      <div>
        {
          capabilitiesStatus === 'pending' &&
            <p className="py-2">
              Recent changes to your Stripe account are currently pending.<br />Payments and payouts will be enabled as soon as your updated information has been approved by Stripe.
            </p>
        }
        {
          capabilitiesStatus === 'inactive' &&
            <p className="py-2">
              Payments and payouts are disabled for this account until missing business information is updated.<br />Click <strong>Update Information</strong> to provide required information on Stripe.
            </p>
        }
        {
          capabilitiesStatus === 'restricted_soon' &&
            <p className="py-2">
              Your Stripe account will not be able to receive payouts and process payments if missing business information is not updated.<br />Click <strong>Update Information</strong> to provide required information on Stripe.
            </p>
        }
        {
          capabilitiesStatus === 'error' &&
            <p className="py-2">
              { /* eslint-disable-next-line max-len */ }
              We detected an issue with your Stripe account. Please contact your InfluenceLogic representative for further assistance: <a href={`mailto:${creator.adminUser?.email || 'support@influencelogic.com'}`}>{creator.adminUser?.email || 'support@influencelogic.com'}</a>
            </p>
        }
        {
          capabilitiesStatus === 'inactive' &&
          <Row className="align-items-center text-secondary">
            <Col xs="12" lg="auto">
              <a href="/stripe/dashboard" target="_blank" rel="noreferrer">
                <Button
                  outline
                  color="primary"
                  className="mt-2 mb-0"
                  size="md"
                >
                  <p>Update Information &nbsp;<SendIcon /> </p>
                </Button>
              </a>
            </Col>
          </Row>
        }
      </div>
    </Alert>
  );
};

InfoRequiredAlert.propTypes = {
  portal: PropTypes.string,
  creator: PropTypes.objectOf(PropTypes.any).isRequired,
};

InfoRequiredAlert.defaultProps = {
  portal: 'creator',
};

export default InfoRequiredAlert;