import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {
  form,
  resources,
  branding,
  paths,
  session,
  dashboard,
  notices,
  pages,
  versions,
  theme,
  rtl,
  sidebar,
  customizer,
  locale,
  assets,
  table,
  modals,
} from './store';
import creatorPayoutReducer from '../../../redux/reducers/creator/Payout';
import creatorCreatorReducer from '../../../redux/reducers/creator/Creator';

import notificationReducer from '../../../redux/reducers/admin/NotificationsReducer';

const reducer = combineReducers({
  form,
  resources,
  branding,
  paths,
  session,
  dashboard,
  notices,
  pages,
  versions,
  theme,
  rtl,
  sidebar,
  customizer,
  locale,
  assets,
  table,
  modals,

  notifications: notificationReducer,
  // globalSelected,
  creatorPayoutReducer,
  creatorCreatorReducer,

});

// const middleware = [thunk];
const creatorStore = (initialState = {}) => configureStore({
  reducer,
  middleware: () => [thunk],
  preloadedState: initialState,
  devTools: window.process.env.NODE_ENV === 'local', // Enables redux devtools. Allows to time-travel redux store.
});

export default creatorStore;
