import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Alert from '../../../../shared/easydev2/shared/components/Alert';
import SummaryPanel from './summary/SummaryPanel';

class CreatorDashboard extends PureComponent {
  static propTypes = {
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
    dateFrom: PropTypes.number.isRequired,
    dateTo: PropTypes.number.isRequired,
  }

  render() {
    const { influencer } = this.props;
    if (influencer.deals && influencer.deals.length > 0) {
      let upfrontCount = 0;
      influencer.deals.forEach((deal) => {
        if (deal.dealTerms.type_of_deal === 'Upfront Only') {
          upfrontCount += 1;
        }
      });
      if (upfrontCount === influencer.deals.length) {
        return (
          <Col style={{ padding: '0px' }}>
            <Alert color="info" className="mb-4" icon>
              <p>
                <span className="bold-text">
                  You are not currently enrolled in any sponsorship programs with referral payouts.
                  If you are interested in learning more about these programs, please contact <a href={`mailto:${influencer && influencer.adminUser ? influencer.adminUser.email : 'sales@influencelogic.com'}`} >your CSP</a>. Thanks!
                </span>
              </p>
            </Alert>
          </Col>
        );
      }
    }
    return (
      <div style={{ width: '100%' }}>
        <SummaryPanel
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
        />
      </div>
    );
  }
}

export default connect(state => ({
  influencer: state.session,
  dateFrom: state.creatorCreatorReducer.dateFrom,
  dateTo: state.creatorCreatorReducer.dateTo,
}))(CreatorDashboard);
