import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';


export default () =>
  (
    <div className="login-footer">
      <Row>
        <Col lg="3">
          © {moment().format('YYYY')} InfluenceLogic, LLC
        </Col>
        <Col lg="2">
          <a href="https://www.influencelogic.com/privacy-policy">Privacy Policy</a>
        </Col>
        <Col lg="2">
          <a href="https://www.influencelogic.com/terms-of-service">Terms of Service</a>
        </Col>
        <Col lg="2">
          <a href="https://influencelogic.com/services-agreement/">Creator Service Agreement</a>
        </Col>
      </Row>
    </div>
  );
