/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';

class IntervalDatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    minDate: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    maxDate: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    startDate: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    endDate: PropTypes.object,
  };

  static defaultProps = {
    minDate: null,
    maxDate: null,
    startDate: null,
    endDate: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate ? props.startDate : null,
      endDate: props.endDate ? props.endDate : null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChangeStart = startDate => this.handleChange({ startDate });

  handleChangeEnd = endDate => this.handleChange({ endDate });

  handleChange({ startDate, endDate }) {
    const { startDate: stateStartDate, endDate: stateEndDate } = this.state;

    const { onChange } = this.props;

    startDate = startDate || stateStartDate;
    endDate = endDate || stateEndDate;

    this.setState({ startDate, endDate });
    onChange({ start: startDate, end: endDate });
  }

  render() {
    const {
      startDate,
      endDate,
    } = this.state;

    const {
      minDate,
      maxDate,
    } = this.props;

    return (
      <Row
        className="date-picker date-picker--interval"
        style={{ margin: '16px 0 0 0' }}
      >
        <Col
          sm="5"
          style={{ padding: '0' }}
        >
          <DatePicker
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.handleChangeStart}
            dateFormat="MMM d, yyyy"
            placeholderText="From"
            dropDownMode="select"
            className="form-control"
          />
          <svg
            className="mdi-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            style={{
              position: 'absolute',
              top: '13px',
              right: '7px',
              fill: '#999',
            }}
          >
            <path
              d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
            />
          </svg>
        </Col>
        <Col
          sm="2"
          style={{ paddingTop: '12px' }}
        >
          <MinusIcon className="date-picker__svg" />
        </Col>
        <Col
          sm="5"
          style={{ padding: '0' }}
        >
          <DatePicker
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.handleChangeEnd}
            dateFormat="MMM d, yyyy"
            placeholderText="To"
            dropDownMode="select"
            className="form-control"
          />
          <svg
            className="mdi-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            style={{
              position: 'absolute',
              top: '13px',
              right: '7px',
              fill: '#999',
            }}
          >
            <path
              d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
            />
          </svg>
        </Col>
      </Row>
    );
  }
}

const IntervalDatePicker = (props) => {
  const {
    input,
    minDate,
    maxDate,
    startDate,
    endDate,
  } = props;
  console.log(input);
  return (
    <IntervalDatePickerField
      {...input}
      minDate={minDate}
      maxDate={maxDate}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

IntervalDatePicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default IntervalDatePicker;
