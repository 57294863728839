import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import { initializeSession } from '../../App/store/store';

class LogInSecret extends PureComponent {
  constructor() {
    super();

    this.state = {
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { match, dispatch } = this.props;
    // eslint-disable-next-line react/prop-types
    if (match && match.params && match.params.token) {
      axios.post(
        '/api/creator/spy-login',
        {
          // eslint-disable-next-line react/prop-types
          spyLoginToken: match.params.token,
        },
      ).then((response) => {
        // eslint-disable-next-line eqeqeq
        if (response.data.success == true) {
          dispatch(initializeSession(response.data.user));
          window.location.replace('/');
        } else {
          this.setState({ error: 'Error while loading data', loading: false });
          console.log('CATCH ERROR: ', response.data.error);
        }
      }).catch((error) => {
        this.setState({ error: 'Error while loading data', loading: false });
        console.log('CATCH ERROR: ', error);
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    return (
      <div>
        <div className="account account--not-photo login-bg">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head-login" style={{ marginBottom: '10px' }}>
                <img src="/images/general/logo_dark_small.png" alt="IL Creator Portal" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h2>Creator Portal</h2>
                {loading && (
                  <h4>Logging in...</h4>
                )}
              </div>
              <div style={{ textAlign: 'center', width: '100%' }}>
                {error && (
                  <p style={{ margin: '16px' }}>{error}</p>
                )}
                {loading && (
                  <div
                    className="spinner-border il-text-gray mt-3"
                    role="status"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(() => ({}))(LogInSecret);

