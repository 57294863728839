import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CreatorRoutes from './CreatorRoutes';


const WrappedRoutes = ({ creator }) => (
  <div>
    {creator && creator._id ? <Route path="/" component={CreatorRoutes} /> : <Redirect to="/login" />}
  </div>
);
WrappedRoutes.propTypes = {
  creator: PropTypes.objectOf(PropTypes.any),
};
WrappedRoutes.defaultProps = {
  creator: null,
};

export default connect(state => ({
  creator: state.session,
}))(WrappedRoutes);

