import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
  Button,
  ButtonToolbar,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import SendIcon from 'mdi-react/SendIcon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { formatString } from '../../../shared/helpers/WVFormatter';
import WVValidator from '../../../shared/helpers/WVValidator';
import withNotice from '../../App/store/with-notice';
import { initializeSession } from '../../App/store/store';
import ButtonWithSpinner from '../../../shared/components/ButtonWithSpinner';
import StripeBalance from '../Payments/components/StripeBalance';
import DropZoneField from '../../../shared/components/form/DropZone';

class AccountSettings extends PureComponent {
  static propTypes = {
    addNotice: PropTypes.func.isRequired,
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      errors: {},
      isPopoverOpen: false,
      modals: {
        disconnectStripe: false,
      },
      loading: {
        deactivateStripe: false,
        submit: false,
      },
      logo: [],
      file: null,
    };
  }

  componentDidMount() {
    document.title = 'IL Creator Portal: Account Settings';

    const { influencer } = this.props;

    if (influencer && influencer.logo) {
      this.fillLogo(influencer.logo);
    }

    // eslint-disable-next-line
    this.setState({
      email: influencer.email,
      firstName: formatString(influencer.first_name),
      lastName: formatString(influencer.last_name),
    });
  }

  // HANDLERS
  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onSubmit = () => {
    if (this.state.loading.submit) { return; }
    if (!this.validateErrors()) { return; }

    this.setState({
      loading: {
        submit: true,
      },
    });

    const { dispatch } = this.props;

    const {
      file,
      email,
      firstName,
      lastName,
      oldPassword,
      newPassword,
      repeatPassword,
    } = this.state;

    const data = new FormData();
    data.append('email', email);
    data.append('firstName', firstName);
    data.append('lastName', lastName);
    data.append('oldPassword', oldPassword);
    data.append('newPassword', newPassword);
    data.append('repeatPassword', repeatPassword);
    data.append('file', file);
    if (file !== null && file.length === 0) {
      data.append('removeLogo', 'true');
    }

    axios({
      method: 'post',
      url: '/api/creator/account-settings',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success && response.data.influencer) {
        this.props.addNotice({
          message: 'Your account settings have been updated successfully.',
          type: 'success',
        });

        setTimeout(() => {
          axios({
            method: 'get',
            url: `/api/creator/fetch-creator?id=${response.data.influencer._id}`,
          }).then((res) => {
            dispatch(initializeSession(res.data.influencer));
            this.props.history.push('/');
          });
        }, 1000);
      } else {
        this.processSubmitError(response.data.error);
      }
    }).catch((error) => {
      this.processSubmitError(error.message);
    });
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  onDeactivateStripeAccountButtton = () => {
    this.setState({
      loading: {
        deactivateStripe: true,
      },
    });

    const { dispatch } = this.props;

    axios({
      method: 'post',
      url: '/api/creator/stripe/delete-account',
    }).then((response) => {
      if (response.data.success && response.data.influencer) {
        this.setState({
          loading: {
            deactivateStripe: false,
          },
          modals: {
            disconnectStripe: false,
          },
        });

        this.props.addNotice({
          message: 'Successfully deactivated your Stripe account!',
          type: 'success',
        });

        dispatch(initializeSession(response.data.influencer));
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
    });
  };

  fillLogo = (logo) => {
    this.setState({
      logo: [{ preview: logo, name: logo }],
    });
  };

  handleChange = (files) => {
    const image = files.length ? files[0] : '';

    this.setState({
      logo: files,
      file: image,
    });
  };

  toggleStripeModal = () => {
    this.setState(prevState => ({ modals: { disconnectStripe: !prevState.modals.disconnectStripe } }));
  }

  togglePopover = () => {
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen,
    });
  };

  // METHODS
  validateErrors() {
    const errors = {};
    const {
      email,
      firstName,
      lastName,
      oldPassword,
      newPassword,
      repeatPassword,
    } = this.state;

    if (!WVValidator.isEmail(email)) {
      errors.email = 'Please check your email format!';
    }
    if (WVValidator.isEmpty(firstName)) {
      errors.firstName = 'Please provide your first name.';
    }
    if (WVValidator.isEmpty(lastName)) {
      errors.lastName = 'Please provide your last name.';
    }

    if (WVValidator.isEmpty(oldPassword) &&
        WVValidator.isEmpty(newPassword) &&
        WVValidator.isEmpty(repeatPassword)) {
      this.setState({ errors });

      return Object.entries(errors).length === 0;
    }

    if (WVValidator.isEmpty(this.state.oldPassword)) {
      errors.oldPassword = 'Please provide your old password!';
    }
    if (WVValidator.isEmpty(this.state.newPassword)) {
      errors.newPassword = 'Please provide your new password!';
    }
    if (this.state.newPassword.length < 8) {
      errors.newPassword = 'Yor password must consist of at least 8 symbols';
    } else if (!WVValidator.isValidPassword(this.state.newPassword)) {
      // eslint-disable-next-line
      errors.newPassword = 'Your password must consist of at least 1 number, 1 capital letter, 1 lowercase letter and 1 special character ($.,)(!%^*#)';
    }
    if (WVValidator.isEmpty(this.state.repeatPassword)) {
      errors.repeatPassword = 'Please confirm your new password!';
    }
    if (this.state.newPassword !== this.state.repeatPassword) {
      errors.repeatPassword = 'Passwords do not match!';
    }

    this.setState({
      errors,
    });

    return Object.entries(errors).length === 0;
  }

  showPassword = (property) => {
    this.setState({
      [`${property}`]: !this.state[`${property}`],
    });
  }
  showOldPassword = () => {
    this.showPassword('showOldPassword');
  };
  showNewPassword = () => {
    this.showPassword('showNewPassword');
  };
  showRepeatPassword = () => {
    this.showPassword('showRepeatPassword');
  };

  hasError = name => Object.prototype.hasOwnProperty.call(this.state.errors, name);

  processSubmitError(error) {
    this.props.addNotice({
      message: error,
      type: 'error',
    });

    this.setState({
      loading: {
        submit: false,
      },
    });
  }

  renderStripeControls() {
    const { influencer } = this.props;

    if (influencer.payment && WVValidator.isEmpty(influencer.payment.stripe.accountId)) {
      return (
        <Link to="/payments">
          <Button
            outline
            color="success"
            className="mt-3"
            size="md"
          >
            <p>Setup Payment Account &nbsp;<SendIcon /> </p>
          </Button>
        </Link>
      );
    }

    const { disconnectStripe } = this.state.modals;

    return (
      <Row className="align-items-center">
        <Col xs="auto" className="my-1">
          <div className="il-payments-logo" />
        </Col>
        <Col xs="auto" className="pl-1 my-1">
          <h4>{`${influencer.first_name} ${influencer.last_name}`}</h4>
          <h5 className="text-muted">{influencer.accountType !== undefined && influencer.accountType === 1 ? 'Company Account' : 'Personal Account'}</h5>
          {
            (influencer.accountType !== undefined && influencer.accountType === 1) &&
            <h4>{`${influencer.companyName}`}</h4>
          }
          <a
            className="il-payments-green-link"
            href="/stripe/dashboard?account=true"
            target="_blank"
          >
            View Account on Stripe
          </a>
        </Col>
        <Col xs="auto">
          <StripeBalance />
        </Col>
        <Col xs="auto" className="my-1 ml-auto">
          <Button
            outline
            className="btn-sm mr-0 mb-2"
            type="button"
            color="danger"
            onClick={this.toggleStripeModal}
          >
            <span>Delete Stripe Account</span>
          </Button>
          <Modal isOpen={disconnectStripe} toggle={this.toggleStripeModal} className="delete-modal">
            <ModalHeader>Delete Stripe Account</ModalHeader>
            <ModalBody>
              <p>
                Please confirm that you wish to delete your Stripe account.
                After you complete this step, you will no longer receive payouts from InfluenceLogic via Stripe.
                If you decide to change your mind at a later date,
                you can setup and reconnect to Stripe on the Settings page.
              </p>
            </ModalBody>
            <ModalFooter>
              <ButtonWithSpinner
                type="button"
                color="danger"
                onClick={this.onDeactivateStripeAccountButtton}
                loading={this.state.loading.deactivateStripe}
              >
                <span>Delete</span>
              </ButtonWithSpinner>{' '}
              <Button color="secondary" onClick={this.toggleStripeModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <h6 className="text-muted text-left mb-0">
            Note that your account balance must
            <br />
            be $0.00 before you are able to proceed.
          </h6>
        </Col>
      </Row>
    );
  }

  render() {
    const { influencer } = this.props;
    const { logo } = this.state;
    // console.log('LSD LOG influencer:', influencer);

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Account Settings</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} sm={12}>
            <Card>
              <CardBody>
                {(influencer.first_name || influencer.last_name) && (
                  <div className="card__title">
                    <h5 className="bold-text">{formatString(influencer.first_name)} {formatString(influencer.last_name)}</h5>
                  </div>
                )}
                {/* INFORMATION */}
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Information</h4>
                  <hr className="mt-3 mb-4" />
                  <FormGroup>
                    <Label for="emailInput">Email</Label>
                    <Input
                      name="email"
                      type="email"
                      bsSize="lg"
                      value={this.state.email}
                      invalid={this.hasError('email')}
                      onChange={this.onDataChanged}
                      readOnly
                    />
                    <FormFeedback>{this.state.errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="firstNameInput">First Name</Label>
                    <Input
                      name="firstName"
                      type="text"
                      bsSize="lg"
                      value={this.state.firstName ?? ''}
                      invalid={this.hasError('firstName')}
                      onChange={this.onDataChanged}
                    />
                    <FormFeedback>{this.state.errors.firstName}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="lastNameInput">Last Name</Label>
                    <Input
                      name="lastName"
                      type="text"
                      bsSize="lg"
                      value={this.state.lastName ?? ''}
                      invalid={this.hasError('lastName')}
                      onChange={this.onDataChanged}
                    />
                    <FormFeedback>{this.state.errors.lastName}</FormFeedback>
                  </FormGroup>
                </section>
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Logo Image</h4>
                  <hr className="mt-3 mb-4" />
                  <DropZoneField
                    input={{
                      name: 'logo',
                      id: 'logo',
                      value: logo,
                      onChange: this.handleChange,
                    }}
                  />
                </section>
                {/* PAYMENT INFORMATION */}
                {
                  !influencer.payment.billCom.isDefault &&
                  <section>
                    <h4 className="il-color-deep-blue font-weight-bold mt-5">Payment Information</h4>
                    <h6 className="text-muted mb-0">We use <strong>Stripe</strong> to make sure you get paid on time and to keep your personal bank and details secure.</h6>
                    <hr className="mt-3 mb-4" />
                    <div className="border rounded p-3">
                      {this.renderStripeControls()}
                    </div>
                  </section>
                }
                {/* PASSWORD */}
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Change Password</h4>
                  <hr className="mt-3 mb-4" />
                  <FormGroup>
                    <Label for="oldPasswordInput">Old Password</Label>
                    <InputGroup size="lg">
                      <Input
                        name="oldPassword"
                        type={this.state.showOldPassword ? 'text' : 'password'}
                        bsSize="lg"
                        invalid={this.hasError('oldPassword')}
                        onChange={this.onDataChanged}
                      />
                      <InputGroupAddon addonType="append">
                        <button
                          className={`form__form-group-button${this.state.showOldPassword ? ' active' : ''}`}
                          onClick={e => this.showOldPassword(e)}
                          data-property-name="showOldPassword"
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                      <FormFeedback>{this.state.errors.oldPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="newPasswordInput">New Password</Label>
                    <InputGroup size="lg">
                      <Input
                        id="newPasswordInput"
                        name="newPassword"
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        bsSize="lg"
                        invalid={this.hasError('newPassword')}
                        onChange={this.onDataChanged}
                      />
                      <Popover
                        id="popover-new-password"
                        placement="left"
                        isOpen={this.state.isPopoverOpen}
                        target="newPasswordInput"
                        trigger="focus"
                        toggle={this.togglePopover}
                      >
                        <PopoverHeader>Password requirements:</PopoverHeader>
                        <PopoverBody>
                          <p>
                            at least 1 Upper Case letter<br />
                            at least 1 Lower Case letter<br />
                            at least 1 number<br />
                            at least 1 special character ($.,)(!%^*#)<br />
                            at least 8 characters in length<br />
                            New Password and Confirm Password should match
                          </p>
                        </PopoverBody>
                      </Popover>
                      <InputGroupAddon addonType="append">
                        <button
                          className={`form__form-group-button${this.state.showNewPassword ? ' active' : ''}`}
                          onClick={e => this.showNewPassword(e)}
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                      <FormFeedback>{this.state.errors.newPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="passwordInput">Repeat New Password</Label>
                    <InputGroup size="lg">
                      <Input
                        name="repeatPassword"
                        type={this.state.showRepeatPassword ? 'text' : 'password'}
                        bsSize="lg"
                        invalid={this.hasError('repeatPassword')}
                        onChange={this.onDataChanged}
                      />
                      <InputGroupAddon addonType="append">
                        <button
                          className={`form__form-group-button${this.state.showRepeatPassword ? ' active' : ''}`}
                          onClick={e => this.showRepeatPassword(e)}
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                      <FormFeedback>{this.state.errors.repeatPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </section>
                {/* BUTTONS */}
                <ButtonToolbar className="form__button-toolbar mt-5">
                  <Button
                    className="px-5 btn btn-sm"
                    type="button"
                    color="primary"
                    onClick={this.onSubmit}
                  >
                    {
                      this.state.loading.submit &&
                      <span><div className="spinner-border text-light spinner-border-sm" /> </span>
                    }
                    <span>Save</span>
                  </Button>
                  <Button
                    className="px-5 btn btn-sm"
                    type="button"
                    onClick={this.onCancel}
                    disabled={this.state.loading.submit}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  rtl: state.rtl,
  influencer: state.session,
});

export default withNotice(connect(mapStateToProps)(AccountSettings));
