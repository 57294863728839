import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
// import moment from 'moment';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import formater from '../../../../../shared/helpers/WVFormatter';

const columnTransform = {
  date: {
    title: 'Date',
    valueFunc: record => (record.payoutDate ? formater.formatDateForCreatorsPaymentsTables(record.payoutDate) : '-'),
    sort: record => (record.payoutDate),
  },
  payouts: {
    title: 'Payout',
    valueFunc: record => formater.formatCurrency(Number(record.payout || 0)),
    sort: record => Number(record.payout || 0),
  },
};

const PayoutModal = ({ isOpen, toggleModal }) => {
  const [payableItems, setPayableItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isOpen === true) {
      setLoading(true);
      axios.get('/api/creator/dashboard/get-payouts').then((res) => {
        setPayableItems(res.data.data);
        setLoading(false);
      }).catch((error) => {
        console.error(error);
      });
    }
    return () => {
      // setPayableItems([]);
    };
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="delete-modal ltr-support" size="lg" style={{ maxWidth: '800px' }}>
      <ModalHeader>Previous Payouts</ModalHeader>
      <ModalBody>
        {!loading ? (
          <MatTableForRedux
            dataArray={payableItems}
            selectable={false}
            columns={columnTransform}
            version={(new Date()).toString()}
            rowsPerPage={5}
            defaultOrder={{
              order: 'desc',
              orderBy: 'date',
            }}
          />) : (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};
PayoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
export default PayoutModal;
