import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import Panel from '../../../shared/components/Panel';
import DocumentsTable from './components/Table';
import withNotice from '../../App/store/with-notice';
import Breadcrumb from '../../../shared/components/BreadCrumbs';
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaxFormUploadModal, { TaxFormUploadModalId } from "../../../shared/components/modals/TaxFormUploadModal";
import Hook from "../../../shared/hooks";

const DocumentsDashboard = ({ addNotice }) => {
  DocumentsDashboard.propTypes = {
    addNotice: PropTypes.func.isRequired,
  };

  const [taxFormData, setTaxFormData] = useState(null);
  const [creatorData, setCreatorData] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [taxMessage, setTaxMessage] = useState('Loading Tax data...');

  const [showModalTaxFormUpload] = Hook.useShowModalHook(TaxFormUploadModalId);

  useEffect(() => {
    document.title = 'Documents';
    // eslint-disable-next-line react/prop-types
    const search = new URLSearchParams(window.location.search);
    const isSigned = search.get('signed');
    const brand = search.get('brand');
    if (isSigned && brand) {
      addNotice({
        message: `The ${brand} Service Agreement was successfully signed. You can view and download a copy of the signed document using the Preview and Download buttons. Thank you!`,
        type: 'success',
      });
    }
    fetchData();
  }, []);

  const fetchData = () => {
    setTaxMessage('Loading Tax data...');

    axios.get('/api/creator/get-tax-form').then((response) => {
      if (response.data.success) {
        // const currentYear = new Date().getFullYear();
        const { creator, taxForm } = response.data;
        const taxFormObj = taxForm[0];

        let message = taxFormObj ? taxFormObj.approvalStatus?.value : 'Missing';
        // if (!creator.validTaxForm?.value && taxForm.length === 0) {
        //   message = 'Missing';
        // }
        if (taxForm.length > 0 && taxFormObj.approvalStatus.value === 'Needs Review') {
          message = 'In Review';
        }
        if (taxForm.length > 0 && taxFormObj.approvalStatus.value === 'Rejected') {
          message = 'Rejected';
        }
        if (taxForm.length > 0 && taxFormObj.approvalStatus.value === 'Expired') {
          message = 'Expired';
        }
        if (taxForm.length > 0 && taxFormObj.approvalStatus.value === 'Approved') {
          const date = new Date(taxFormObj.approvalStatus.history[taxFormObj.approvalStatus.history.length - 1].dateModified);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          const formattedDate = date.toLocaleDateString('en-US', options);
          message = `On File, Approved (${formattedDate})`
        }
        if (message === 'Missing' && creator.payment.stripe?.accountId) {
          message = 'On File (Stripe)';
        }

        setTaxMessage(message);
        setCreatorData(creator);
        setTaxFormData(taxForm[0]);
      }
      // setLoading(false);
    }).catch((error) => {
      console.log(error);
      // setLoading(false);
    });
  }

  const search = new URLSearchParams(window.location.search);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumb
            links={[
              { title: 'Home', path: '/' },
              { title: 'Documents', path: null, className: 'last-item-color' },
            ]}
            isBackButton
            backButtonUrl="/"
          />
        </Col>
      </Row>
      <div className="documents-section">
        <Row>
          <Col md={12} className="my-3">
            <div>
              <img className="documents-title-image" alt="Documents" src="/images/creator/documentsIcon.png" />
            </div>
            <h3 className="documents-title" style={{ float: 'left' }}>Documents</h3>
          </Col>
          <Panel showButtons={false} panelClass="panel-no-vertical-padding">
            <Row className="my-2">
              <Col>
                <span style={{ padding: '16px', color: '#646777', fontSize: '18px', lineHeight: '24px' }}>Tax Form: {taxMessage}</span>
                {(taxMessage === 'Missing' || taxMessage === 'Expired' || taxMessage === 'Rejected') && (
                  <Tooltip title="Upload Tax Form">
                    <IconButton size="small" onClick={() => showModalTaxFormUpload({ source: 'creator' })} style={{ marginBottom: '6px' }}><FontAwesomeIcon icon="upload" /></IconButton>
                  </Tooltip>
                )}
                {taxFormData?.approvalStatus?.value === 'Approved' && (
                  <a href={`/api/creator/get-view?url=${taxFormData.filePath}`}
                     title="View Tax Document" target="_blank" rel="noreferrer"
                     style={{fontSize: '30px'}} className="pr-2 text-dark"><i className="fa fa-file-pdf"/></a>
                )}
                <TaxFormUploadModal person="creator" record={creatorData} updateData={() => { fetchData(); } }/>
              </Col>
            </Row>
            <DocumentsTable addNotice={addNotice} signed={search.get('signed')}/>
          </Panel>
        </Row>
      </div>
    </Container>
  );
};

export default withNotice(DocumentsDashboard);
