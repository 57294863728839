import React from 'react';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
// import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from 'mdi-react/InformationIcon';
import { Button, Card, CardBody /* , Popover, PopoverHeader, PopoverBody */ } from 'reactstrap';
import formater from '../../../../../shared/helpers/WVFormatter';

const OffersSummary = ({ isFetching, offers }) => {
  // const [popoverOpen, setPopoverOpen] = useState(null);

  // const handlePopover = (open, id) => {
  //   setPopoverOpen({ popoverOpen: open ? null : id });
  // };
  console.log(offers);
  return (
    <Card >
      <CardBody style={{ overflowX: 'auto' }}>
        <h3 className="il-color-deep-blue font-weight-bold summary-title">Offer Summary</h3>
        {isFetching && (<div className="text-center w-100"><div className="spinner-border il-text-gray mt-3" role="status" /></div>)}
        {!isFetching ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Brand</strong></TableCell>
                <TableCell><strong>Offer</strong></TableCell>
                <TableCell align="left"><strong>Payout</strong></TableCell>
                <TableCell align="left"><strong>Clicks</strong></TableCell>
                <TableCell align="left"><strong>Conversions</strong></TableCell>
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map(offer => (
                <TableRow key={offer.offer_id}>
                  <TableCell component="th" scope="row">
                    {offer.offer}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {offer.offer_name}
                  </TableCell>
                  {offer.message && !offer.haveUpfront ? (
                    // eslint-disable-next-line react/no-danger
                    <TableCell align="left" colSpan={4}><span className="color-red" dangerouslySetInnerHTML={{ __html: offer.message }} /></TableCell>
                  ) : ([
                    (<TableCell align="left" key={1}>{formater.formatCurrency(parseFloat(offer.haveUpfront ? 0 : offer.payout))}</TableCell>),
                    (<TableCell align="left" key={3}>{formater.formatIntNumber(offer.clicks)}</TableCell>),
                    (<TableCell align="left" key={2}>{formater.formatIntNumber(offer.conversions)}</TableCell>),
                    (
                      <TableCell align="right" key={4}>
                        <Link to={`/dashboard/${offer._id}`}>
                          <Button color="primary" className="my-0 radius-button px-2" style={{ minWidth: '130px' }}>
                            <FontAwesomeIcon icon={['fa', 'eye']} />{' '}View Offer
                          </Button>
                        </Link>
                      </TableCell>),
                      ]
                    )}
                </TableRow>
                ))}
            </TableBody>
          </Table>) : null}
      </CardBody>
    </Card>
  );
};

OffersSummary.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  offers: PropTypes.arrayOf(PropTypes.shape({
    payout: PropTypes.number,
    offer: PropTypes.string.isRequired,
    clicks: PropTypes.number.isRequired,
    conversions: PropTypes.number.isRequired,
  })).isRequired,
};

export default OffersSummary;
