import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import { axios } from 'ApiClient';
import WVFormatter from '../../../../shared/helpers/WVFormatter';

function GraySpinner() {
  return (
    <span>
      <div
        className="spinner-border il-text-gray spinner-border-sm"
        style={{ width: '1.5rem', height: '1.5rem', borderWidth: '.10em' }}
      />
    </span>
  );
}

class StripeBalance extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentBalance: 0,
      availableBalance: 0,
      loading: {
        balance: false,
      },
    };
  }

  componentDidMount() {
    this.fetchStripeBalance();
  }

  fetchStripeBalance = () => {
    this.setState({
      loading: {
        balance: true,
      },
    });

    axios.get('/api/creator/stripe/balance').then((response) => {
      const { balance } = response.data;
      this.setState({
        currentBalance: balance ? balance.available[0].amount + balance.pending[0].amount : 0,
        availableBalance: balance ? balance.available[0].amount : 0,
        loading: {
          balance: false,
        },
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: {
          balance: false,
        },
      });
    });
  }

  render() {
    return (
      <div>
        <h5 className="il-payments-balance-label mb-2">Your Balance</h5>
        <Row className="align-items-center">
          <Col xs="auto">
            <h4 className="il-payments-balance">
              {WVFormatter.formatCurrency(this.state.currentBalance / 100)}
            </h4>
            <h5 className="il-payments-available-balance">
              {WVFormatter.formatCurrency(this.state.availableBalance / 100)} available
            </h5>
          </Col>
          <Col xs="auto">
            {this.state.loading.balance && GraySpinner()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default StripeBalance;
