import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Spinner, Button } from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import { openSignUrl } from '../../../../shared/helpers/models/ServiceAgreement';

export default class Table extends PureComponent {
  static propTypes = {
    addNotice: PropTypes.func,
    signed: PropTypes.string,
  };

  static defaultProps = {
    addNotice: () => {},
    signed: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      agreements: [],
      loading: false,
      isDownloaded: false,
    };
    this.sign = this.sign.bind(this);
    this.signKeyDown = this.signKeyDown.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    if (this.props.signed) {
      setTimeout(() => {
        this.setState({ isDownloaded: true });
      }, 20000);
    }
  }

  dateSigned = (record, format) => {
    let value = record._id.toString() === this.props.signed ? new Date() : record.creatorDateSigned;
    return value ? moment(value).format(format) : '';
  };

  cols() {
    return {
      brand: {
        title: 'Brand',
        valueFunc: record => (record.advertisers[0].companyName),
        sort: record => (record.advertisers[0].companyName),
      },
      offer: {
        title: 'Offer',
        valueFunc: record => ((record.type === 'keen') ? 'Service Provider Agreement' : record.offers[0].name),
        sort: record => ((record.type === 'keen') ? 'Service Provider Agreement' : record.offers[0].name),
      },
      status: {
        title: 'Status',
        valueFunc: (record) => {
          if (record.status === 'completed' || record._id.toString() === this.props.signed) {
            return (
              <div className="marker marker-green font-weight-normal">Complete</div>
            );
          }
          if (record.engine === 'dropbox' && record.envelopeId) {
            return (
              <div className="marker marker-yellow font-weight-normal">Sent</div>
            );
          }
          return (
            <div className="marker marker-yellow marker-yellow-clickable font-weight-normal" data-id={record._id} onClick={this.sign} onKeyDown={this.signKeyDown} role="button" tabIndex={0}>Sign</div>
          );
        },
        sort: (record) => {
          if (record.status === 'completed' || record._id.toString() === this.props.signed) return 1;
          return 0;
        },
      },
      date_signed: {
        title: 'Date Signed',
        valueFunc: record => this.dateSigned(record, 'M/D/YYYY'),
        sort: record => this.dateSigned(record, 'YYYY-MM-DD'),
      },
      buttons: {
        title: '',
        valueFunc: (record) => {
          if (record._id.toString() === this.props.signed) {
            if (!this.state.isDownloaded) {
              return 'Downloading...';
            }
          } else if (!record.fileUrl) {
            return '';
          }
          return (
            <div style={{ textAlign: 'right' }}>
              <a href={`/documents/view?id=${record._id}`} target="_blank" rel="noopener noreferrer">
                <Button
                  color="primary"
                  size="md"
                  className="mt-1 mb-1 creator-doc-table-btn"
                  style={{ borderRadius: '12px' }}
                >
                  <span className="lnr lnr-eye" />
                  View
                </Button>
              </a>
              <a href={`/documents/download?id=${record._id}`} target="_blank" rel="noopener noreferrer">
                <Button
                  color="primary"
                  size="md"
                  className="mt-1 mb-1 ml-3 creator-doc-table-btn"
                  style={{ borderRadius: '12px' }}
                >
                  <span className="lnr lnr-download" />
                  Download
                </Button>
              </a>
            </div>
          );
        },
        disableSortBy: true,
      },
    };
  }

  fetchData() {
    this.setState({
      loading: true,
    });

    axios.get('/api/creator/agreements').then((response) => {
      const { success, agreements } = response.data;
      const stateData = { loading: false };
      if (success) {
        stateData.agreements = agreements;
      }
      this.setState(stateData);
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: false,
      });
    });
  }

  sign(e) {
    const id = e.target.getAttribute('data-id');
    this.setState({ loading: true });
    axios.post('/api/creator/sign-agreement', { id }).then((response) => {
      const { success, url, engine } = response.data;
      if (success && url) {
        openSignUrl(url, (loading) => { this.setState({ loading }); }, { engine });
      } else {
        if (response.data.error) {
          this.props.addNotice(response.data.error);
        }
        this.setState({ loading: false });
      }
    }).catch((error) => {
      console.log(error);
      this.props.addNotice('Unable to load agreement. Please contact us.');
      this.setState({ loading: false });
    });
  }

  signKeyDown(e) {
    if (e.keyCode === 13) this.sign(e);
  }

  render() {
    const { loading, agreements } = this.state;
    return (
      <div className="creator-documents-table">
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {!loading &&
          <Row>
            <MatTableForRedux
              dataArray={agreements}
              selectable={false}
              columns={this.cols()}
              version={(new Date()).toString()}
              defaultOrder={{
                order: 'asc',
                orderBy: 'brand',
              }}
              rowsPerPage={10}
              hideToolbar
            />
          </Row>
        }
      </div>
    );
  }
}
